
import React from 'react';
import { connect } from 'react-redux';
import { deleteAssetMarkers,fetchMarkers } from '../../redux/reducers/reducer';
import Modal from 'react-modal';



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  }
};
Modal.setAppElement('#root')

class DeleteMarkerPopup extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  componentDidMount(){
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.formSuccess !== this.props.formSuccess) {
      if (nextProps.formSuccess) {
        this.setState({ modalIsOpen: false });
        let formData = {};
        formData["mode"] = "fetch";
        this.props.fetchMarkers(this.props.assetid, formData);
      }
    }
  }



  render() {
    let self = this;
    const { assetid } = this.props;
    let formData = {};
    formData["mode"] = "delete"
    formData["markerid"] = this.props.markerid;

    return (
      <div>
        <a href="#" data-toggle="tooltip" data-placement="top" title="Delete" onClick={this.openModal} className="btn btn-warning-link greylink"><i className="fa fa-trash-o"></i></a>
        <div >
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            className="custom_modal"
          >
            {/* <a href="#" onClick={this.closeModal} className="u-alert-close"><img src={imagesAssetResourcesPrefix + themePrefix + "playerSidenavIcons/close.png"} /></a> */}
            <a href="#" onClick={() => { this.closeModal}} className='close material-symbols-outlined'>close </a>
            <h4 ref={subtitle => this.subtitle = subtitle}>
              {/* <i className="fa fa-exclamation-circle margrt10"></i> */}
              Delete Marker</h4>
            <p ref={subtitle => this.subtitle = subtitle}>Are you sure you want to delete the marker? <br />This Process cannot be undone.</p>
            <div className="modalfooter new-btns-u">
              {this.props.loading ? null : <button onClick={this.closeModal} className="no_btn popup_btn" >No</button>}
              <button onClick={e => this.props.deleteAssetMarkers(assetid,formData)} disabled={this.props.loading} className="btn btn-primary">{this.props.loading ? "Deleting..." : "Yes"}</button>

            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapState = ({ loading, formSuccess, markers }) => ({ loading, formSuccess, markers });
const mapDispatch = { deleteAssetMarkers,fetchMarkers };
export default connect(mapState, mapDispatch)(DeleteMarkerPopup);
