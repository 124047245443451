import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import { manageLookUp } from "../../redux/reducers/reducer";
// import DonationAndVoting from '../channelWeb/DonationAndVoting';
let selectOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, "Unlimited"];
let priceArray = ["5", "20", "50"];
let arrayType = ["timezone"];

let monthlyItem = {
  id: "monthly",
  isPaymentRequired: false,
  label: "Monthly",
  period: "30",
  planId: "monthly",
  planName: "Monthly",
  price: "",
};

let yearlyItem = {
  id: "yearly",
  isPaymentRequired: false,
  label: "Yearly",
  period: "30",
  planId: "yearly",
  planName: "Yearly",
  price: "",
};
class Availability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      errors: {},
      alertShow: false,
      custom: false,
      formvalues: {},
      monthlyItem,
      yearlyItem,
      preview: {
        enable: false,
        maxMin: 1,
        startAt: 0,
      },
      availability: this.props.availability || {},
      embed: {
        allow: false,
        sidePanel: {
          display: false,
          enable: false,
        },
      },
      donation: {
        allow: false,
        sessions: [],
      },
      voting: {
        allow: false,
        sessions: [],
      },
      likeDislike: {
        allow: false,
      },
      rating: {
        allow: false,
      },
      countriesArray: [],
      autoStart: this.props.autoStart,
      autoEnd: this.props.autoEnd,
      subscriptionsData: [],
      territory:
        (this.props.availability && this.props.availability.territory) || [],
      disabledStyle: { cursor: "default", pointerEvents: "none", opacity: 0.6 },
    };
    // console.log('props', this.props)
  }

  componentDidMount() {
    let self = this;
    let siteSettings = window.site;
    let siteConfig = siteSettings.config || {};
    let subscriptionPlans =
      (siteConfig.subscription &&
        siteConfig.subscription.enable &&
        siteConfig.subscription.subscriptionPlans &&
        siteConfig.subscription.subscriptionPlans.plans) ||
      [];
    if (subscriptionPlans) {
      let subscriptions = subscriptionPlans.filter((el) => +el.price > 0);
      //   console.log("subscriptionsddddd", subscriptions);
      _.each(subscriptions, function (item) {
        item["id"] = item.planId;
        item["label"] = item.planName + " (" + item.price + ")";
      });
      self.setState({ subscriptionsData: subscriptions });
    }

    var lookupsType = { type: arrayType, mode: "fetch" }
    self.props.manageLookUp(lookupsType);

  }
  componentDidUpdate(prevProps) {
    let self = this;
    // console.log("this.props", this.props);
    if (prevProps.manageLookUps !== this.props.manageLookUps) {
      let manageLookUps = this.props.manageLookUps || [];
      if (manageLookUps && manageLookUps.length > 0)
        manageLookUps.forEach((item1) => {
          arrayType.forEach((item2) => {
            if (item1.type == item2) {
              let itemArray = item1.type + "Data"
              if (item1.type == "rating") {
                self.setState({
                  [itemArray]: item1
                })
              } else {
                self.setState({
                  [itemArray]: item1.values
                })
              }
            }
          });
        });
      // this.handleClips();
    }
  }
  componentWillReceiveProps(nextProps) {
    let self = this;
    if (nextProps.availability != this.state.availability) {
      setTimeout(() => {
        self.setState({ availability: this.props.availability });
      }, 1000);
    }
    if (nextProps.donation != this.state.donation) {
      setTimeout(() => {
        self.setState({ donation: this.props.donation });
      }, 1000);
    }
    if (nextProps.voting != this.state.voting) {
      setTimeout(() => {
        self.setState({ voting: this.props.voting });
      }, 1000);
    }
    if (nextProps.likeDislike != this.state.likeDislike) {
      setTimeout(() => {
        self.setState({ likeDislike: this.props.likeDislike });
      }, 1000);
    }
    if (nextProps.embed != this.state.embed) {
      setTimeout(() => {
        self.setState({ embed: this.props.embed });
      }, 1000);
    }
    if (nextProps.preview != this.state.preview) {
      setTimeout(() => {
        self.setState({ preview: this.props.preview });
      }, 1000);
    }
    if (nextProps.rating != this.state.rating) {
      setTimeout(() => {
        self.setState({ rating: this.props.rating });
      }, 1000);
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onChangeAvailability(e, type, plan, contentObj, content, item) {
    let self = this;
    let errors = {};
    let name = e.target.name;
    let value = e.target.value;
    let formIsValid = true;

   let formvalues = { ...self.state.formvalues,  ...self.state.formData};
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    // if (value === 0) value=JSON.stringify(value)
    let customVal = value;
    customVal = parseFloat(customVal);
    let isNaNVal = isNaN(customVal);
    if (!isNaNVal && customVal != 0) {
      value = customVal;
    }
    let defaultPreviewObj = {
      enable: false,
      maxMin: 1,
      startAt: 0,
    };
    if (content) {
      let obj = { ...self.state[type] };
      obj[plan] = obj[plan] ? obj[plan] : {};
      obj[plan][contentObj] = obj[plan][contentObj]
        ? obj[plan][contentObj]
        : {};
      obj[plan][contentObj][content] = obj[plan][contentObj][content]
        ? obj[plan][contentObj][content]
        : {};
      obj[plan][contentObj][content][name] = value;
      if (content === "contentPrice") {
        let contentValue = false;
        if (value > 0) {
          contentValue = true;
        }
        obj[plan][contentObj]["contentType"] = obj[plan][contentObj][
          "contentType"
        ]
          ? obj[plan][contentObj]["contentType"]
          : {};
        obj[plan][contentObj]["contentType"][name] = contentValue;
      }
      formvalues[type] = obj;
      self.setState({ formvalues, executing: false, [type]: obj });
    } else if (contentObj) {
      let obj = { ...self.state[type] };
      obj[plan] = obj[plan] ? obj[plan] : { allow: true };

      let subObj = {};
      if (plan == "buyplans") {
        if (item) {
          obj[plan]["plan"] = "subscription";
          subObj = {
            subscription: true,
            deviceLimit: 1,
            viewsLimit: "Unlimited",
            contentPrice: { planPrice: item.price ? item.price : 0 },
            planId: item.planId ? item.planId : "",
            planName: item.planName ? item.planName : "",
            contentType: { hd: true },
          };
          if (item.sku) subObj["sku"] = item.sku;
          if (item.quickbooksId) subObj["quickbooksId"] = item.quickbooksId;
        } else {
          obj[plan]["plan"] = "purchase";
          subObj = { subscription: false, contentType: { hd: true } };
          if (
            contentObj == "rent" ||
            contentObj == "buy" ||
            contentObj == "gift"
          )
            (subObj["viewsLimit"] = 1), (subObj["deviceLimit"] = 1);
          if (contentObj == "rent") subObj["rentalPeriod"] = 24;
        }
      }
      obj[plan][contentObj] = obj[plan][contentObj]
        ? obj[plan][contentObj]
        : subObj;
      obj[plan][contentObj][name] = value;
      if (value === false) {
        // console.log("obj0", obj)
        delete obj[plan][contentObj];
        delete obj[plan]["plan"];
        // if (plan != 'free') {
        //   obj[plan][name] = value;
        //   formvalues['preview'] = defaultPreviewObj
        //   self.setState({
        //     ['preview']: defaultPreviewObj
        //   })
        // }
      }
      if (Object.keys(obj[plan]).length < 2) delete obj[plan];
      formvalues[type] = obj;
      self.setState({ formvalues, executing: false, [type]: obj });
    } else if (plan) {
      let obj = { ...self.state[type] };
      let freeObj = {};

      if (plan == "free") {
        freeObj = { viewsLimit: 1, deviceLimit: 1, contentType: { hd: true } };
        formvalues["preview"] = defaultPreviewObj;
        self.setState({ ["preview"]: defaultPreviewObj });
      }
      // console.log("freeObj", freeObj)
      obj[plan] = obj[plan] ? obj[plan] : freeObj;
      if (value === false) {
        // console.log("obj1", obj);
        if (plan == "free") {
          obj["publicViewing"] = false;
        }
        delete obj[plan];
        if (plan == "buyplans" || plan == "free") {
          // if (plan != 'free') obj[plan][name] = value;
          //console.log("obj", obj);
          formvalues["preview"] = defaultPreviewObj;
          self.setState({ ["preview"]: defaultPreviewObj });
        }
      } else {
        obj[plan][name] = value;
        obj[plan]["plan"] = plan;
      }
      formvalues[type] = obj;
      self.setState({ formvalues, executing: false, [type]: obj });
    } else {
      let obj = { ...self.state[type] };
      obj[name] = value;
      formvalues[type] = obj;
      self.setState({ formvalues, executing: false, [type]: obj });
    }
    this.props.handleAvailability(formvalues);
    if (item) {
      if (item.planId === "monthly") {
        let newMonthlyItem = {
          id: "monthly",
          isPaymentRequired: false,
          label: "Monthly",
          period: "30",
          planId: "monthly",
          planName: "Monthly",
          price:
            formvalues.availability.buyplans.monthly.contentPrice.planPrice ||
            0,
        };
        this.setState({ monthlyItem: newMonthlyItem });
      } else {
        let newYearlyItem = {
          id: "yearly",
          isPaymentRequired: false,
          label: "Yearly",
          period: "30",
          planId: "yearly",
          planName: "Yearly",
          price:
            formvalues.availability.buyplans.yearly.contentPrice.planPrice || 0,
        };
        this.setState({ yearlyItem: newYearlyItem });
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(e, type) {
    let self = this;
    let name = e.target.name;
    let value = e.target.value;
    let customVal = value;
    if (customVal != "") customVal = +customVal;
    let isNaNVal = isNaN(customVal);
    if (!isNaNVal) {
      value = customVal;
    }
    let formvalues = { ...self.state.formvalues,  ...self.state.getformvalues };
    let obj = { ...self.state[type] };
    formvalues[type] = formvalues[type] ? formvalues[type] : obj;
    let errors = Object.assign({}, this.state.errors);
    if (e.target.type == "checkbox") {
      obj[name] = e.target.checked;
      formvalues[type] = obj;
      self.setState({ [type]: obj });
    } else {
      if (name == "availableTo") {
        //console.log("availableTo",value)
        if (value == "") {
          //console.log("if",value)
          obj["availabilityToGMT"] = value;
        } else {
          delete obj["availabilityToGMT"];
        }
        if (obj.availableFrom) {
          let fromdate = moment(obj.availableFrom);
          let todate = moment(value);
          let datetimediff = moment(todate).isAfter(moment(fromdate));
          //console.log("availableTo", datetimediff)
          // let count = todate.diff(fromdate, 'days');
          // if (count > 0) {
          if (datetimediff) {
            delete errors[name];
            obj[name] = value;
          } else {
            obj[name] = value;
            errors[name] = "To date must be greater than from date";
          }
          this.setState({ errors });
        } else {
          obj[name] = value;
        }
      } else if (name == "availableFrom") {
        // console.log("availableFrom",value)
        if (value == "") {
          obj["availabilityFromGMT"] = value;
        } else {
          delete obj["availabilityFromGMT"];
        }
        if (obj.availableTo) {
          let fromdate = moment(value);
          let todate = moment(obj.availableTo);
          let datetimediff = moment(todate).isAfter(moment(fromdate));
          // console.log("availableFrom", datetimediff)
          if (datetimediff) {
            delete errors["availableTo"];
            obj[name] = value;
          } else {
            obj[name] = value;
            errors["availableTo"] = "To date must be greater than from date";
          }
          this.setState({ errors });
        } else {
          obj[name] = value;
        }
      } else {
        obj[name] = value;
      }
      formvalues[type] = obj;
      self.setState({ [type]: obj });
    }

    // if (type == 'preview' && formvalues.availability && formvalues.availability.free) {
    //   formvalues.availability['publicViewing'] = false
    // }
    self.setState({ formvalues, executing: false });
    
    this.props.handleAvailability(formvalues);
  }

  radioSubscription(e, type, item) {
    let self = this;
    let name = e.target.name;
    let value = item == "" || item == undefined ? e.target.checked : item;
    let formvalues = { ...self.state.formvalues,  ...self.state.getformvalues };

    let obj = { ...self.state[type] };
    if (item == "" || item == undefined) {
      obj[name] = value;
    } else {
      obj["plan"] = value;
    }

    if (!!this.state.errors["plan"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["plan"];
      self.setState({ errors });
    }

    if (name == "publicViewing" && value) {
      let freeObj = {
        allow: true,
        viewsLimit: 1,
        deviceLimit: 1,
        contentType: { hd: true },
      };
      obj["free"] = freeObj;
    }

    formvalues[type] = obj;
    self.setState({ formvalues, [type]: obj, executing: false });
    this.props.handleAvailability(formvalues);
  }
  render() {
    var self = this;
    const { monthlyItem, yearlyItem, timezoneData } = this.state;
    const { isChannel, isBulkUpdate, assetType, playlistType, getformvalues, serviceProvider, assetid, contentType } = self.props;
    //console.log("monthly item", self.state.donation);
    // console.log("yearly item", yearlyItem);
    let subscriptionPlans = self.state.subscriptions
      ? self.state.subscriptions
      : self.state.subscriptionsData && self.state.subscriptionsData;
    let availability = self.state.availability || {};
    //console.log('availability',availability)
    let free = availability.free || {};
    // console.log('availability free----------->',free);
    let buyplans = availability.buyplans || {};
    // console.log("buyplans----", buyplans);
    let buy = buyplans.buy || {};
    let publicPlan = buyplans.allow ? true : false;
    let freePlan = buyplans.allow ? true : false;
    let purchasePlan = availability.publicViewing || free.allow ? true : false;
    let subscriptionPlan =
      availability.publicViewing || free.allow ? true : false;
    let preview = self.state.preview || {};

    //let freePreview = free.allow && preview.enable;
    // let purchasePlanEnable = (buy.allow || download.allow || rent.allow || gift.allow)
    // let purchasePreview = purchasePlanEnable && preview.enable;
    //let subscriptionPlanEnable = (buyplans.plan == 'subscription') ? true : false;
    // let subscriptionPreview = subscriptionPlanEnable && preview.enable;
    // let isPreviewEnable = false;
    // let nonPaidTheme = theme.nonPaid || {};
    // let purchasePlansTheme = theme.purchasePlans || {};
    // let subscriptionPlansTheme = theme.subscriptionPlans || {};
    // let additionalOptionsTheme = theme.aditionalOptions || {};
    let isPreviewEnable =
      (assetType &&
        assetType.toLowerCase() != "image" &&
        assetType.toLowerCase() != "pdf" &&
        assetType.toLowerCase() != "office" &&
        assetType.toLowerCase() != "music") ||
      isBulkUpdate ||
      isChannel;
    // console.log('availablity getformvalues', getformvalues)
    // const isIvsChannel = (getformvalues && getformvalues.serviceprovider!="awsInteractiveVideo") ? true : false 
    //  ['id':'monthly','isPaymentRequired':'false','label':'Monthly (44.99)','period':'30','planId':'monthly','planName':'Monthly','price':'44.99']
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form_section">
            <div className="u-availbility">
            <h3 class="sub_title">Availability</h3>
           
              <div className="row">
                <div className="col-md-4">
                  <div className="inputgroup">
                    <p className="label">Available</p>
                    <input
                      type="datetime-local"
                      className="date_input"
                      name="availableFrom"
                      value={availability.availableFrom}
                      onChange={(e) => self.handleChange(e, "availability")}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="inputgroup">
                    <p className="label">Upto</p>
                    <input
                      type="datetime-local"
                      className="date_input"
                      name="availableTo"
                      value={availability.availableTo}
                      onChange={(e) => self.handleChange(e, "availability")}
                    />
                    <span className="errormsg">
                      {self.state.errors.availableTo}
                    </span>
                  </div>
                </div>

                {!isPreviewEnable && (
                  <div className="col-md-4">
                    <div className="inputgroup">
                      <p className="label">Time zone</p>
                      <select
                        name="timezone"
                        value={availability.timezone}
                        onChange={(e) =>
                          self.handleChange(e, "availability")
                        }
                        className="custom-select form-control"
                      >
                        <option value="">Select</option>
                        {timezoneData &&
                          timezoneData.length > 0 &&
                          timezoneData.map((item, i) => {
                            return (
                              <option key={i} name={item}>
                                {item}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                )}

                {isPreviewEnable && (
                  <div className="col-md-4">
                    <div className="inputgroup">
                      <p className="label">Time zone</p>
                      <select
                        name="timezone"
                        value={availability.timezone}
                        onChange={(e) =>
                          self.handleChange(e, "availability")
                        }
                        className="custom-select form-control"
                      >
                        <option value="">Select</option>
                        {timezoneData &&
                          timezoneData.length > 0 &&
                          timezoneData.map((item, i) => {
                            return (
                              <option key={i} name={item}>
                                {item}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                )}
                {(serviceProvider != "awsInteractiveVideo" || assetid) && <><div className="col-md-4">
                  {isPreviewEnable && (
                    <div className="inputgroup">
                      <p className="label">Preview</p>
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          disabled={free.allow}
                          name="enable"
                          checked={preview.enable ? preview.enable : false}
                          onChange={(e) => self.handleChange(e, "preview")}
                          className="custom-control-input"
                          id="AllowFreePreview"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="AllowFreePreview"
                        >
                          Preview
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                  {/* <div className="col-md-2 dsp_flex">
                    <label className="start_at">Start At</label>
                  </div> */}
                   </>}
                {isPreviewEnable && (serviceProvider != "awsInteractiveVideo" || assetid) && (
                  <div className="col-md-2">
                    <div className="inputgroup">
                      <p className="label">Minutes</p>
                      <input
                        type="number"
                        name="startAt"
                        min={0}
                        readOnly={isChannel}
                        disabled={!preview.enable}
                        value={
                          self.state["preview"] &&
                          self.state["preview"].startAt
                        }
                        onChange={(e) => self.handleChange(e, "preview")}
                        className="usm-inpt"
                      />
                    </div>
                  </div>
                )}

                {isPreviewEnable && (serviceProvider != "awsInteractiveVideo" || assetid) && (
                  <div className="col-md-4">
                    <div className="inputgroup">
                      <p className="label">Preview Duration</p>
                      <select
                        name="maxMin"
                        min="0"
                        disabled={!preview.enable}
                        value={self.state["preview"].maxMin}
                        onChange={(e) => self.handleChange(e, "preview")}
                        className="usm-inpt"
                      >
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>

              <div className="form_tab">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mb-20">
                      <div className="col-md-3">
                        <div className={publicPlan ? "disable1" : ""}>
                          <div className="custom-control custom-checkbox my-1 mr-sm-2">
                            {" "}
                            <input
                              type="checkbox"
                              name="publicViewing"
                              checked={
                                availability.publicViewing
                                  ? availability.publicViewing
                                  : false
                              }
                              onChange={(e) =>
                                self.radioSubscription(
                                  e,
                                  "availability",
                                  ""
                                )
                              }
                              className="custom-control-input"
                              id="publicView"
                              disabled={publicPlan}
                            />{" "}
                            <label
                              className="custom-control-label"
                              htmlFor="publicView"
                            >
                              Public Viewing
                            </label>
                          </div>
                        </div>{" "}
                      </div>
                      <div className="col-md-3">
                        <div className={freePlan ? "disable1" : ""}>
                          <div>
                            <div className="custom-control custom-checkbox my-1 mr-sm-2">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name="allow"
                                onChange={(e) =>
                                  self.onChangeAvailability(
                                    e,
                                    "availability",
                                    "free"
                                  )
                                }
                                checked={free.allow ? free.allow : false}
                                id="free"
                                disabled={freePlan}
                              />
                              <label
                                htmlFor="free"
                                className="custom-control-label bold "
                              >
                                Free
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div
                          className={
                            purchasePlan
                              ? "disable1 block_scroll"
                              : "block_scroll"
                          }
                        >
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="allow"
                              className="custom-control-input"
                              id="buy"
                              onChange={(e) =>
                                self.onChangeAvailability(
                                  e,
                                  "availability",
                                  "buyplans",
                                  "buy"
                                )
                              }
                              checked={buy.allow ? buy.allow : false}
                              disabled={free.allow}
                            />
                            <label
                              htmlFor="buy"
                              className="custom-control-label"
                            >
                              Buy
                            </label>
                          </div>
                          <div>
                            <div className="inputgroup">
                              <p className="label"> Price</p>
                              <input
                                type="number"
                                min="0"
                                disabled={!buy.allow}
                                className="tabinput"
                                placeholder="0.00"
                                name="hd"
                                value={
                                  buy &&
                                    buy["contentPrice"] &&
                                    buy["contentPrice"].hd
                                    ? buy["contentPrice"].hd
                                    : ""
                                }
                                onChange={(e) =>
                                  self.onChangeAvailability(
                                    e,
                                    "availability",
                                    "buyplans",
                                    "buy",
                                    "contentPrice"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ........................ */}
                      <div className="col-md-4">
                        <div
                          className={
                            subscriptionPlan
                              ? "disable1 block_scroll"
                              : "block_scroll"
                          }
                        >
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="allow"
                              className="custom-control-input"
                              id={monthlyItem.planId}
                              onChange={(e) =>
                                self.onChangeAvailability(
                                  e,
                                  "availability",
                                  "buyplans",
                                  monthlyItem.planId,
                                  "",
                                  monthlyItem
                                )
                              }
                              checked={
                                buyplans &&
                                  buyplans[monthlyItem.planId] &&
                                  buyplans[monthlyItem.planId].allow
                                  ? buyplans[monthlyItem.planId].allow
                                  : false
                              }
                              disabled={free.allow}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={monthlyItem.planId}
                            >
                              {monthlyItem.planName}
                            </label>
                          </div>
                          <div>
                            <div className="inputgroup">
                              <p
                                className="label"
                                htmlFor={monthlyItem.planName + "hd"}
                              >
                                Price
                              </p>
                              <input
                                type="number"
                                min="0"
                                //   disabled={buyplans && buyplans['monthly'] && buyplans['monthly'].allow ? buyplans['monthly'].allow :false }
                                disabled={!buyplans[monthlyItem.planId]}
                                id={monthlyItem.planName + "hd"}
                                className="tabinput"
                                placeholder="0.00"
                                name="planPrice"
                                value={
                                  (buyplans &&
                                    buyplans[monthlyItem.planId] &&
                                    buyplans[monthlyItem.planId][
                                    "contentPrice"
                                    ] &&
                                    buyplans[monthlyItem.planId][
                                      "contentPrice"
                                    ].planPrice) ||
                                  ""
                                }
                                onChange={(e) =>
                                  self.onChangeAvailability(
                                    e,
                                    "availability",
                                    "buyplans",
                                    monthlyItem.planId,
                                    "contentPrice",
                                    monthlyItem
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className={
                            subscriptionPlan
                              ? "disable1 block_scroll"
                              : "block_scroll"
                          }
                        >
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="allow"
                              className="custom-control-input"
                              id={yearlyItem.planId}
                              onChange={(e) =>
                                self.onChangeAvailability(
                                  e,
                                  "availability",
                                  "buyplans",
                                  yearlyItem.planId,
                                  "",
                                  yearlyItem
                                )
                              }
                              checked={
                                buyplans &&
                                  buyplans[yearlyItem.planId] &&
                                  buyplans[yearlyItem.planId].allow
                                  ? buyplans[yearlyItem.planId]
                                    .allow
                                  : false
                              }
                              disabled={free.allow}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={yearlyItem.planId}
                            >
                              {yearlyItem.planName}
                            </label>
                          </div>
                          <div>
                            <div className="inputgroup">
                              <p
                                className="label"
                                htmlFor={yearlyItem.planName + "hd"}
                              >
                                Price
                              </p>
                              <input
                                type="number"
                                min="0"
                                //   disabled={buyplans && buyplans['monthly'] && buyplans['monthly'].allow ? buyplans['monthly'].allow :false }
                                disabled={
                                  !buyplans[yearlyItem.planId]
                                }
                                id={yearlyItem.planName + "hd"}
                                className="tabinput"
                                placeholder="0.00"
                                name="planPrice"
                                value={
                                  (buyplans &&
                                    buyplans[yearlyItem.planId] &&
                                    buyplans[yearlyItem.planId][
                                    "contentPrice"
                                    ] &&
                                    buyplans[yearlyItem.planId][
                                      "contentPrice"
                                    ].planPrice) ||
                                  ""
                                }
                                onChange={(e) =>
                                  self.onChangeAvailability(
                                    e,
                                    "availability",
                                    "buyplans",
                                    yearlyItem.planId,
                                    "contentPrice",
                                    yearlyItem
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ml-u">
                <Modal
                  isOpen={this.state.alertShow}
                  className="u-brand-popup u-pad-20t"
                >
                  <ModalBody className="pad-20">
                    <div className="row">
                      <div className="col-md-12">
                        {" "}
                        <h2 className="text-center">
                          {" "}
                          Only one plan is selected free or buyplans
                        </h2>
                      </div>
                      <div className="col-md-12 text-center">
                        <button
                          type="button"
                          className="btn btn-lg btn-danger"
                          onClick={(e) =>
                            self.setState({ alertShow: false })
                          }
                        >
                          <span>Ok</span>{" "}
                        </button>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>

            {/* {contentType == "event" &&
              <DonationAndVoting emailSending={this.props.handleAvailability} donation={this.state.donation} voting={this.state.voting} likeDislike={this.state.likeDislike} duration={this.props.duration} isEvent={this.props.isEvent} />} */}
            {/* <div className="col-md-3"></div> */}
          </div>
        </div>
        {/* {getformvalues && getformvalues.assetid ? <MiniPlayer getformvalues={getformvalues} /> : ''} */}
        {/* <div className="col-md-3">
          <div className="video_preview">
            <div
              className="video"
              onClick={() =>
                self.playerClick(self.props.getformvalues, "playMovie")
              }
              id="playMovie"
            >
              <a href="#" className="video_play">
                {" "}
                <span className="material-icons">play_circle</span>
              </a>
              {!popupPlayer && (
                <img src="https://vlive.imgix.net/screening/images/landscape_logo.jpg?auto=compress,format" />
              )}
            </div>
            <div className="video_info">
              {self.state.getformvalues && self.state.getformvalues.created && <div className='date'>Created: {self.state.getformvalues.created}</div>}
              {self.state.getformvalues &&
                self.state.getformvalues.formatedduration && (
                  <div className="drs">
                    {self.state.getformvalues.formatedduration}
                  </div>
                )}
            </div>
          </div>
        </div> */}
        {/* <div className='col-md-3'>
                                 <div className='video_preview'>
                                    <div className='video'>
                                        <a href="#" className='video_play'>  <span className="material-icons">play_circle</span></a>
                                  
                                        <img src='https://vlive.imgix.net/screening/images/landscape_logo.jpg?auto=compress,format' />
                                    </div>
                                    <div className='video_info'>
                                        <div className='date'>Created: 08-11-2022</div>
                                        <div className='drs'>5:25</div>
                                    </div>
                                 </div>
                                    
        </div> */}
      </div>
    );
  }
}

Availability.defaultProps = {
  theme: {
    display: true,
    enable: true,
    nonPaid: {
      display: true,
      enable: true,
      publicViewing: {
        display: true,
        enable: true,
      },
      free: {
        display: true,
        enable: true,
      },
      maxDevices: {
        display: true,
        enable: true,
      },
      maxDownloads: {
        display: true,
        enable: true,
      },
      period: {
        display: true,
        enable: true,
      },
      maxConcurrentViews: {
        display: true,
        enable: true,
      },
      maxHistoricalViews: {
        display: true,
        enable: true,
      },
      maxGroupUsers: {
        display: true,
        enable: true,
      },
    },
    purchasePlans: {
      display: true,
      enable: true,
      buy: {
        display: true,
        enable: true,
      },
      rent: {
        display: true,
        enable: true,
      },
      download: {
        display: true,
        enable: true,
      },
      gift: {
        display: true,
        enable: true,
      },
      maxDevices: {
        display: true,
        enable: true,
      },
      maxDownloads: {
        display: true,
        enable: true,
      },
      period: {
        display: true,
        enable: true,
      },
      maxConcurrentViews: {
        display: true,
        enable: true,
      },
      maxHistoricalViews: {
        display: true,
        enable: true,
      },
      maxGroupUsers: {
        display: true,
        enable: true,
      },
    },
    subscriptionPlans: {
      display: true,
      enable: true,
      maxDevices: {
        display: true,
        enable: true,
      },
      maxDownloads: {
        display: true,
        enable: true,
      },
      period: {
        display: true,
        enable: true,
      },
    },
    aditionalOptions: {
      display: true,
      enable: true,
      autoStart: {
        display: true,
        enable: true,
      },
      autoEnd: {
        display: true,
        enable: true,
      },
      rating: {
        display: true,
        enable: true,
      },
      allowEmbed: {
        display: true,
        enable: true,
      },
      sidePanel: {
        display: true,
        enable: true,
      },
      donation: {
        display: true,
        enable: true,
      },
      voting: {
        display: true,
        enable: true,
      },
    },
  },
};

const mapState = ({ manageLookUps }) => ({
  manageLookUps
});
const mapDispatch = { manageLookUp };
export default connect(mapState, mapDispatch)(Availability);