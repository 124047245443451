import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import axios from 'axios';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker'
import moment from 'moment';
import { CompactPicker } from 'react-color';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import Alert from "sweetalert2";
import VideoPlayer from '../videoPlayer/VideoPlayer';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { manageScheduling, manageSchedules, getAssets, playlistAssets, scheduleEventsToS3, getPlaybackUrls, manageChannels, getChannels, createMediatailorChannel, fetchMarkers, inserAssetMarkers, msTimeMilliseconds, getVodSources, getSourceLocation, deleteAssetMarkers } from '../../redux/reducers/reducer';
import { location, removeSpecialCharecters } from '../../utils/utils';
import SelectionsExample from '../SelectionsExample'
import SweetAlert from 'react-bootstrap-sweetalert';
import asyncLoop from "node-async-loop";
import Header from '../authentication/Header'
import Footer from '../authentication/Footer'
import Loader from "../Loader";
import VideoMarkers from "../markers/VideoMarkers";
import Availability from '../scheduler/Availability';
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./calendar.css";
import './date.css';
import { setTranslate3d } from 'rsuite/esm/List/helper/utils';
import ReactFileReader from 'react-file-reader';
const csv = require('csvtojson')
import * as XLSX from "xlsx";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
const disabledStyle = { cursor: "default", pointerEvents: "none", opacity: 0.6 };
let urlParams = location("channelCode");
let imgUrl = '';
let eventFormatDuration = '';
let siteSettings = window.site;
let appSettings = window.app
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let user_id = localStorage.getItem('user_id');
let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
let siteConfig = siteSettings && siteSettings.config;
let defaultLandscape = siteConfig && siteConfig.defaultLandscape;
class Scheduling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weekendsVisible: true,
            currentEvents: [],
            calendarEvents: [],
            events: [],
            activity: 'fetch',
            activeTab: '1',
            isOpen: false,
            assetData: {},
            assetCount: 12,
            pageCount: 12,
            pageNumber: 1,
            assetsData: [],
            loadbtn: "Load More",
            assetTotal: 0,
            noOfAssets: 0,
            isLoading: true,
            isSementsLoading: false,
            isAddScheduleOpen: false,
            showAlert: false,
            errors: {},
            errorMsg: '',
            isMetaDataReady: false,
            eventsData: [],
            addMetadata: false,
            assetFile: false,
            noOfPlaylists: 0,
            playlistTotal: 0,
            playlistPageNumber: 1,
            playlistPageCount: 12,
            isUpdateEnabled: false,
            assetInfoData: {},
            formData: {},
            showDeleteEventAlert: false,
            availabilityPopup: false,
            availability: { territory: [], excludedTerritory: [] },
            buttonClass: "btn btn-green jr-btn un-btn",
            buttonClassGray: "btn btn-gray jr-btn un-btn",
            status: "Inactive",
            isSegmentPopup: false,
            segmentData: {},
            isExpand: false,
            markerData: {},
            showDeletePopup: false,
        }
        this.calendarComponentRef = React.createRef()
        this.handleAvailability = this.handleAvailability.bind(this);
    }

    convertAssestToEvent(playlistAssetsData) {
        let self = this;
        let events = [];
        playlistAssetsData && playlistAssetsData.map((item) => {
            let obj = {
                title: item.playlistname,
                // start: new Date(item.startTime),
                id: item.assetid,
                imageurl: item.landscape_logo_image ? item.landscape_logo_image : defaultLandscape,
                totalduration: item.totalduration,
                assets: item.assets,
                uuid: item.uuid,
            }
            events.push(obj)
        })

        this.setState({ events });
    }


    convertRecordingsToEvent(assets) {
        let self = this;

        let recordingAssets = [];
        assets && assets.map((item) => {
            let obj = {
                title: item.assetname,
                // start: new Date(item.startTime),
                id: item.assetid,
                imageurl: item.landscape_logo_image ? item.landscape_logo_image : defaultLandscape,
                totalduration: item.totalduration
            }
            recordingAssets.push(obj)
        })

        this.setState({ recordingAssets });
    }

    componentDidMount() {
        let self = this;
        const { assetCount, pageNumber, playlistPageCount, playlistPageNumber } = this.state;
        let userCode = localStorage.getItem('userCode') || '';
        let payload = { assetType: ['Standalone', 'Recording'], assetCount, pageNumber, }
        this.props.getAssets(payload);
        let playlistPayload = { isActive: true, type: 'envoiPlaylistByUsers', playlistPageCount, playlistPageNumber, }

        this.props.playlistAssets(playlistPayload);
        // this.convertAssestToEvent();
        let latestScheduleUUID = self.generateUUID();
        let draggableEl = document.getElementById("external-events");
        new Draggable(draggableEl, {
            itemSelector: ".fc-event",
            eventData: function (eventEl) {
                if (self.state.calendarId) {
                    let eventInfo = eventEl.getAttribute("data-for");
                    eventInfo = JSON.parse(eventInfo);
                    let title = eventInfo.title;
                    let id = eventEl.getAttribute("data");
                    return {
                        title: title,
                        id: id,
                        extendedProps: { scheduleUUID: latestScheduleUUID, calendarId: self.state.calendarId, description: '', playlistData: eventInfo }
                    };
                } else {
                    // console.log("schedule required !")
                    self.setState({ errorMsg: 'Schedule name required!', showAlert: true })
                }


            },

            eventOverlap: function (stillEvent, movingEvent) {
                return stillEvent.allDay && movingEvent.allDay;
            },
        });
        // let scheulesBody = { "activity": "fetch", }
        // this.props.manageSchedules(scheulesBody);
        let type = "envoiSchedule"
        let data = { assetCount, pageNumber, type }
        this.props.getChannels(data);

        this.props.getVodSources();
        this.props.getSourceLocation();
    }


    s3CalendarObject = (item) => {
        let fDuration = item.formatedduration ? item.formatedduration : ''
        let obj = {
            title: item.title,
            start: item.start,
            end: item.end,
            classNames: item.classNames ? item.classNames : [],
            extendedProps: {
                envoiAssetId: (item.extendedProps || {}).envoiAssetId,
                formattedDuration: fDuration,
                calendarId: item.calendarId,
                userId: item.userid ? item.userid : user_id,
                appName: item.appname ? item.appname : window.app.appName,
                scheduleId: item && item._id
            },
            durationEditable: false
        }
       // console.log('obj',obj)
        return obj
    }

    componentDidUpdate(prevProps) {
        let self = this;
        let payload;
        let type = "envoiSchedule"
        if (prevProps.channels !== this.props.channels) {
            let channelsData = (this.props.channels || {}).data || [];
            let assetTotal = (this.props.channels || {}).assetTotal || "";
            let noOfAssets = this.state.noOfAssets + channelsData.length;
            let newlyCreatedSchedule = channelsData[channelsData.length - 1];
            let calendarId = newlyCreatedSchedule && newlyCreatedSchedule.channelcode
            //this.setState({ calendarId })
            let body = { "activity": "fetch", calendarId }
            this.props.manageScheduling(body);
            let firstChannel = channelsData && channelsData.length > 0 && channelsData[0];
            this.setState({
                schedulesData: channelsData, assetTotal, noOfAssets, isLoading: false, formData: firstChannel,
                availability: firstChannel && firstChannel.availability,
                // destinations: channelsData.destinations,
                id: firstChannel && firstChannel._id,
                serviceProvider: firstChannel && firstChannel.serviceprovider,
                calendarId,
                isMediatailorCreated: firstChannel && firstChannel.mediatailor
                //audio: audioDevicesData,
                // video: videoDevicesData,
                //playlistbyUsers: channelsData.playlistbyUsers, }, () => {
                // this.loadChannelsOnScroll();
            });
        }
        if (prevProps.manageSchedulingData !== this.props.manageSchedulingData) {
            const manageSchedulingData = (this.props.manageSchedulingData || []);
            self.setState({ allSchedulesData: manageSchedulingData });
            // if (self.state.activity != 'create') {
            let calendarEvents = [];
            manageSchedulingData && Array.isArray(manageSchedulingData) && manageSchedulingData.map((item) => {
                let obj = self.s3CalendarObject(item)
                calendarEvents.push(obj)
            })
            if (manageSchedulingData) {
                self.setState({ calendarEvents: [...calendarEvents], eventsData: [...calendarEvents] });
                // self.setState({ calendarEvents: [...calendarEvents], eventsData: [...calendarEvents] });
            }
            // }
        }
        if ((prevProps.manageSchedulesData !== this.props.manageSchedulesData)) {
            let manageSchedulesData = (this.props.manageSchedulesData || []);
            self.setState({ schedulesData: manageSchedulesData, });
            if (manageSchedulesData.length > 0) {

                let newlyCreatedSchedule = manageSchedulesData[manageSchedulesData.length - 1];
                let calendarId = newlyCreatedSchedule.scheduleId
                this.setState({ calendarId })
                let body = { "activity": "fetch", calendarId }
                this.props.manageScheduling(body);
            }
        }
        if (prevProps.assets != this.props.assets) {
            let assetsData = ((this.props.assets || {}).data || []);
            let assetTotal = (this.props.assets || {}).assetTotal || '';
            let noOfAssets = (this.state.noOfAssets + assetsData.length);
            this.convertRecordingsToEvent(assetsData)
            this.setState({ assetsData, assetTotal, noOfAssets, isLoading: false });
        }

        if (prevProps.playlistAssetsData != this.props.playlistAssetsData) {
            let playlistAssetsData = (this.props.playlistAssetsData || {}).data || [];
            let playlistTotal = (this.props.playlistAssetsData || {}).assetTotal || '';
            let noOfPlaylists = (this.state.noOfPlaylists + playlistAssetsData.length);
            this.setState({ playlistAssetsData, isLoading: false, playlistTotal, noOfPlaylists })
            this.convertAssestToEvent(playlistAssetsData)
        }
        if (prevProps.scheduleEventsData != this.props.scheduleEventsData) {
            this.setState({ isLoading: false, errorMsg: 'Schedule saved successfully', showAlert: true });
        }

        if (prevProps.playbackUrls != this.props.playbackUrls) {
            let playbackUrlsData = (this.props.playbackUrls || {});
            this.setState({ playbackUrlsData })
            let playerUrl = '';
            if (playbackUrlsData) {
                let playerUrls = playbackUrlsData;
                if (playerUrls && playerUrls.playback) {
                    let playbackUrls = playerUrls.playback;
                    if (playbackUrls) {
                        playerUrl = playbackUrls.hlsUrl || playbackUrls.progressiveUrl;
                    }
                }
                // console.log("playerUrls", playerUrls)
                //Added this to play for previous object 
                if (!playerUrl) {
                    playerUrl = (playerUrls.hls) || (playerUrls.m3u8_Url) || (playerUrls.progressive) || (playerUrls.vdms_url) || (playerUrls.iconik_proxies_url) || (playerUrls.mp4url) || (playerUrls.eluvio_url || playerUrls.Mp4_proxy_URL);
                    // console.log("playerUrls22", playerUrl)
                }
            }
            if (!playerUrl) {
                this.setState({
                    showMessage: true
                })
            } else {
                this.setState({ playbackURL: playerUrl })
            }
        }
        if (prevProps.manageChannelsData !== this.props.manageChannelsData) {
            let manageChannelData = (this.props.manageChannelsData || {})[0] || {};
            self.setState({
                channelCode: manageChannelData.channelcode
            });
            if (manageChannelData && manageChannelData._id) {
                payload = { "_id": manageChannelData._id, type }
              //  console.log('payload', payload)
                // self.props.getForms(payload);
                this.props.getChannels(payload);
            }
            else {
                // self.props.getForms(payload)
                payload = { type }
                this.props.getChannels(payload);
            }
        }
        if ((prevProps.mediatailorChannelData !== this.props.mediatailorChannelData)) {
            if (typeof this.props.mediatailorChannelData === 'object' && this.props.mediatailorChannelData !== null) {

                self.setState({ showAlert: true, errorMsg: 'Mediatailor channel created successfully', mediatailorData: this.props.mediatailorChannelData });
            } else {
                self.setState({ showAlert: true, errorMsg: this.props.mediatailorChannelData });
            }

        }
        if (prevProps.markers != this.props.markers) {
            let markersData = (this.props.markers || []);
            this.setState({ markersData, isSementsLoading: false, isLoading: false, });
        }
        if (prevProps.insertMarkerSuccess !== this.props.insertMarkerSuccess) {
            if (this.props.insertMarkerSuccess) {
                let assetInfo = this.state.assetInfo || {}
                let assetId = assetInfo.id ? assetInfo.id : (assetInfo.extendedProps || {}).envoiAssetId;
                let formData = {};
                formData["mode"] = "fetch";
                formData["type"] = "segment";
                self.props.fetchMarkers(assetId, formData);
                self.setState({ showAlert: true, errorMsg: 'Added successfully', segmentData: {}, markerData: {} });

            }
        }

        if (prevProps.updateMarkerSuccess !== this.props.updateMarkerSuccess) {
            if (this.props.updateMarkerSuccess) {
                let assetInfo = this.state.assetInfo || {}
                let assetId = assetInfo.id ? assetInfo.id : (assetInfo.extendedProps || {}).envoiAssetId;
                let formData = {};
                formData["mode"] = "fetch";
                formData["type"] = "segment";
                self.props.fetchMarkers(assetId, formData);
                self.setState({ showAlert: true, errorMsg: 'Deleted successfully', segmentData: {}, markerData: {} });
            }
        }
        if (prevProps.vodSourcesData !== this.props.vodSourcesData) {
            let vodSourcesData = (this.props.vodSourcesData || []);
            this.setState({ vodSourcesData, isLoading: false, });
        }

        if (prevProps.sourceLocationData !== this.props.sourceLocationData) {
          //  console.log("this.props.sourceLocationData", this.props.sourceLocationData)
            let sourceLocation = (this.props.sourceLocationData || '');
            this.setState({ sourceLocationData: [sourceLocation], isLoading: false, });
        }

    }



    loadMore(number, loader) {
        let self = this;
        let pageNumber = number ? number : self.state.pageNumber + 1;
        let assetCount = self.state.pageCount;
        self.setState({
            loadbtn: !loader ? "Loading..." : this.state.loadbtn,
            loading: loader,
            pageNumber: pageNumber,
        });
        let appSettings = window.app || {};
        let appStorage = appSettings.storage || {};
        let token = appStorage.getItem("token");
        let siteSettings = window.site || {};
        let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
        let url = envoiSubmissionApiBaseUrl + "/asset?appname=" + window.app.appName + "&token=" + token + "&assetcount=" + assetCount + "&pageNumber=" + pageNumber + "&env=" + window.app.appEnvName + "&isMarkerDuration=true";
        // let userCode = localStorage.getItem("userCode") || "";
        // if (userCode) url += "&code=" + userCode;
        axios
            .post(url, { assetType: ['Standalone', 'Recording'] })
            .then((response) => {
                self.setState({ assetsData: number ? [] : self.state.assetsData, isLoading: false });
                if (
                    response.data &&
                    response.data.result &&
                    response.data.result.data &&
                    response.data.result.data.length > 0
                ) {
                    asyncLoop(
                        response.data.result && response.data.result.data,
                        function (item, next) {
                            self.setState(
                                {
                                    assetsData: self.state.assetsData.concat(item),
                                    loading: false,
                                    loadbtn: !loader ? "Load More" : this.state.loadbtn,
                                    isLoading: false,
                                },
                                function () {
                                    this.convertRecordingsToEvent(self.state.assetsData)
                                    setTimeout(function () {
                                        next();
                                    }, 100);
                                }
                            );
                        }
                    );
                    let newNoOfAssets = response.data.result.data.length;
                    self.setState({
                        noOfAssets: number
                            ? newNoOfAssets
                            : self.state.noOfAssets + newNoOfAssets,
                        loading: false,
                    });
                } else {
                    self.setState({
                        assetTotal:
                            self.state.noOfAssets + response.data.result.data.length,
                        loading: false,
                    });
                }
            })
            .catch((err) => { });
    }


    loadMorePlaylist(number, loader) {
        let self = this;
        let pageNumber = number ? number : self.state.playlistPageNumber + 1;
        let assetCount = self.state.playlistPageCount;
        self.setState({
            loadbtn: !loader ? "Loading..." : this.state.loadbtn,
            loading: loader,
            playlistPageNumber: pageNumber,
        });
        let appSettings = window.app || {};
        let appStorage = appSettings.storage || {};
        let token = appStorage.getItem("token");
        let siteSettings = window.site || {};
        let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
        let url = envoiSubmissionApiBaseUrl + "/PlayLists?appname=" + window.app.appName + "&token=" + token + "&assetcount=" + assetCount + "&pageNumber=" + pageNumber + "&env=" + window.app.appEnvName;
        // let userCode = localStorage.getItem("userCode") || "";
        // if (userCode) url += "&code=" + userCode;
        axios
            .post(url, { isActive: true, type: 'envoiPlaylistByUsers' })
            .then((response) => {
                self.setState({ playlistAssetsData: number ? [] : self.state.playlistAssetsData, isLoading: false });
                if (
                    response.data &&
                    response.data.result &&
                    response.data.result.data &&
                    response.data.result.data.length > 0
                ) {
                    asyncLoop(
                        response.data.result && response.data.result.data,
                        function (item, next) {
                            self.setState(
                                {
                                    playlistAssetsData: self.state.playlistAssetsData.concat(item),
                                    loading: false,
                                    loadbtn: !loader ? "Load More" : this.state.loadbtn,
                                    isLoading: false,
                                },
                                function () {
                                    self.convertAssestToEvent(self.state.playlistAssetsData)
                                    setTimeout(function () {
                                        next();
                                    }, 100);
                                }
                            );
                        }
                    );
                    let newNoOfAssets = response.data.result.data.length;
                    self.setState({
                        noOfPlaylists: number
                            ? newNoOfAssets
                            : self.state.noOfPlaylists + newNoOfAssets,
                        loading: false,
                    });
                } else {
                    self.setState({
                        playlistTotal:
                            self.state.noOfPlaylists + response.data.result.data.length,
                        loading: false,
                    });
                }
            })
            .catch((err) => { });
    }

    /**
   * when we click on event we are displaying event details
   */
    eventClick = info => {
        let self = this;
      //  console.log("eventClick", info)
        let duration;
        let end;
        if (info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration) {
            duration = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.formatedduration;
        }
        let convertedDateArray = duration && duration.split(' ');
        var endDate;
        if (convertedDateArray && Array.isArray(convertedDateArray) && convertedDateArray.length > 0) {
            let hrs = convertedDateArray[0]
            hrs = parseInt(hrs.replace("h", ""));
            let mts = convertedDateArray[1]
            mts = parseInt(mts.replace("m", ""));
            let scnds = convertedDateArray[2]
            scnds = parseInt(scnds.replace("s", ""));
            endDate = info.event.start;
            endDate.setSeconds(endDate.getSeconds() + scnds);
            endDate.setMinutes(endDate.getMinutes() + mts);
            endDate.setHours(endDate.getHours() + hrs);
           // console.log('endDate', endDate)
        } else {
            endDate = info.event.start;
        }
        end = endDate;
        let payload = { assetId: info.event.id ? info.event.id : (info.event.extendedProps || {}).envoiAssetId }
        // this.getAssetDetails(payload);
        self.setState({ end, assetInfo: info.event || {}, assetInfoData: {}, isUpdateEnabled: false }, () => {
            self.setState({ isMetaDataReady: true })
        })
        this.props.getPlaybackUrls(payload);
        let formData = {};
        formData["mode"] = "fetch";
        formData["type"] = "segment";
        self.props.fetchMarkers(payload.assetId, formData);
        this.setState({ loader: true })
    };


    getAssetDetails(payload) {
        let self = this;
        axios.post(envoiSubmissionApiBaseUrl + '/asset?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&assetid=' + payload.assetId)
            .then((response) => {
                if (response && response.data && response.data.result) {
                    let assetInfo = ((response.data.result || {}).data || [])[0] || {};
                    self.setState({ assetInfo, isMetaDataReady: true, loader: false });
                }
            })
            .catch(error => error)
    }

    generateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        this.setState({
            fileId: uuid.toUpperCase()
        });
        return uuid.toUpperCase();
    }


    eventReceive = info => {
     ///   console.log("info", info)
        let self = this;
        // let assetName = info.event.title;
        // let fullAssetName = assetName && assetName.split('-');
        let title = info.event.title;
        let assetId = info.event.id;
        let start = info.event.start;
        let duration;

        if (info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.totalduration) {
            duration = info && info.event && info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.totalduration;
        }

        let playlistAssets = info.event.extendedProps && info.event.extendedProps.playlistData && info.event.extendedProps.playlistData.assets;


        if (playlistAssets) {
         //   console.log("playlistAssets", playlistAssets);

            if (playlistAssets && playlistAssets.length > 0) {
                let eventList = [];
                let startDateEvent = start;
                playlistAssets.map((element, i) => {
                    let obj = Object.assign({});
                    obj = {
                        title: element.assetname,
                        formatedduration: element.formatedduration || 0,
                        classNames: ['standaloneAsset'],
                        extendedProps: {
                            envoiAssetId: element.assetid,
                            scheduleUUID: info.event.extendedProps.scheduleUUID,
                        },
                        durationEditable: false,
                        activity: "create",
                        type: 'Scheduler',
                        calendarId: info.event.extendedProps.calendarId,
                        channelid: info.event.extendedProps.calendarId,
                        assetid: element.assetid
                    };
                    obj["start"] = new Date(startDateEvent.toString())

                    let endDate = startDateEvent
                    let convertedDateArray = element.formatedduration && element.formatedduration.split(' ');
                    if (convertedDateArray && Array.isArray(convertedDateArray) && convertedDateArray.length > 0) {
                        let hrs = convertedDateArray[0]
                        hrs = parseInt(hrs.replace("h", ""));
                        let mts = convertedDateArray[1]
                        mts = parseInt(mts.replace("m", ""));
                        let scnds = convertedDateArray[2]
                        scnds = parseInt(scnds.replace("s", ""));
                        endDate.setSeconds(endDate.getSeconds() + scnds);
                        endDate.setMinutes(endDate.getMinutes() + mts);
                        endDate.setHours(endDate.getHours() + hrs);
                    }
                    obj["end"] = new Date(endDate.toString())
                    eventList.push({ ...obj })
                })

                let eventsApi = {
                    events: eventList
                }

                console.log('eventsApi', eventsApi)

                //     events = events[events]
                //   ]
                let token = appStorage.getItem('token');
                let calederId = info.event.extendedProps.calendarId || "";
                let scheduleId = info.event.extendedProps.scheduleUUID || "";
                let url = envoiSubmissionApiBaseUrl + "/scheduleimport?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token
                calederId ? (url += `&calederId=${calederId}`) : null;
                scheduleId ? (url += `&scheduleId=${scheduleId}`) : null;

                axios.post(url, eventsApi, { type: 'application/json' })
                    .then((response) => {
                        if (response.data && response.data.statusCode == "200") {
                            let body = { "activity": "fetch", calederId }
                            this.props.manageScheduling(body);
                        }
                    })

                    .catch((err) => {
                        console.error.bind("reducer.js embedLinksData", { err });
                    })
            }
        } else if (self.state.calendarId) {
            let convertedDateArray = duration && duration.split(' ');
            var endDate;
            if (convertedDateArray && Array.isArray(convertedDateArray) && convertedDateArray.length > 0) {
                let hrs = convertedDateArray[0]
                hrs = parseInt(hrs.replace("h", ""));
                let mts = convertedDateArray[1]
                mts = parseInt(mts.replace("m", ""));
                let scnds = convertedDateArray[2]
                scnds = parseInt(scnds.replace("s", ""));
                endDate = info.event.start;
                endDate.setSeconds(endDate.getSeconds() + scnds);
                endDate.setMinutes(endDate.getMinutes() + mts);
                endDate.setHours(endDate.getHours() + hrs);
            }
            let end = endDate;
            let assetInfo = info.event || {};
            self.setState({ end: end, start: start, duration: duration, assetInfo: assetInfo }, () => {
                self.setState({ isMetaDataReady: true, assetInfoData: {}, isUpdateEnabled: false })
            })
            let eventsData = self.state.activity = 'create' ? [...self.state.eventsData] : [...self.state.calendarEvents];
            let payload = { assetId: assetId }
            this.props.getPlaybackUrls(payload);
            let formData = {};
            formData["mode"] = "fetch";
            formData["type"] = "segment";
            self.props.fetchMarkers(payload.assetId, formData);
            let body = {
                title: title,
                start: start,
                end: end,
                formatedduration: duration,
                classNames: ['standaloneAsset'],
                extendedProps: {
                    envoiAssetId: assetId,
                    scheduleUUID: info.event.extendedProps.scheduleUUID,
                },
                durationEditable: false,
                activity: "create",
                type: 'Scheduler',
                calendarId: self.state.calendarId,
                channelid: self.state.calendarId,
                assetid: assetId
            }
            // let body = { "activity": "create", start: new Date(start), channelid: this.state.channelId, "assetid": assetId, "image": "https://vlive.imgix.net/screening/images/portrait_logo.jpg", "assetname": assetName }
            self.props.manageScheduling(body);
            let obj = this.s3CalendarObject(body);
            eventsData.push(obj);
            info.event.remove();
            this.setState({ activity: 'create', eventsData })
        } else {
            // console.log("schedule required !")
            this.setState({ errorMsg: 'Schedule name required!', showAlert: true })
        }
    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    searchOnChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        const { assetCount, pageNumber } = this.state;

        this.setState({ [name]: value });
        if (value == '') {
            if (name == 'playlistSearchInput') {
                let playlistPayload = { isActive: true, type: 'envoiPlaylistByUsers' }
                this.props.playlistAssets(playlistPayload);
            } else {
                let payload = { assetCount, pageNumber, }
                this.props.getAssets(payload);
            }
        }
    }


    searchForAssets(type) {
        const { assetCount, pageNumber } = this.state;
        if (type == 'recordings') {
            this.setState({ recordingAssets: [] });
            let payload = { search: this.state.recodingsSearchInput, assetCount, pageNumber }
            this.props.getAssets(payload);
        } else {
            this.setState({ events: [] });
            let payload = { isActive: true, type: 'envoiPlaylistByUsers', search: this.state.playlistSearchInput }
            this.props.playlistAssets(payload);
        }

    }

    handleKeyDown(e, type) {
        console.log("e", e)
        if (e.key === 'Enter') {
            this.searchForAssets(type);
        }

    }


    calederDataRange = (e) => {
        if (e && e.length > 0) {
            let startDate = moment(e[0]).format('yyyy-MM-DD');
            let endDate = moment(e[1]).format('yyyy-MM-DD');
            this.setState({
                validRange: {
                    start: startDate,
                    end: endDate,
                }
            })
        } else {
            this.setState({
                validRange: {
                }
            })
        }
    }

    addScheduleOpen = (e) => {
        this.setState({
            isAddScheduleOpen: true,
            formData: {},
            availability: {}

        });

    }
    editScheduleOpen = (e) => {
        const { calendarId } = this.state;
        //let body = { "activity": "fetch", scheduleId: calendarId }
        // this.props.manageSchedules(body);
        let payload = { "channelCode": calendarId }
        this.props.getChannels(payload);
        this.setState({
            isAddScheduleOpen: true,

        });

    }
    handleAvailability(data) {
        let self = this;
        //self.setState(data);
        // console.log("data", data)
        let availability = data.availability ? data.availability : self.state.availability;
        let formData = { ...self.state.formData, ...data };
        // console.log('handleAvailability in channel',getFormData)
        let preview = formData.preview ? formData.preview : self.state.preview;
        self.setState({ formData, availability, executing: false, preview });
    }


    filterChannelByCode = (channelCode) => {
        let self = this;
        const filteredChannel = self.state.schedulesData.filter(
            (channel) => ((channel.channelcode) === channelCode)
        );
        if (filteredChannel && filteredChannel.length > 0) {
            console.log("filteredChannel", filteredChannel)
            let isMediatailorCreated = filteredChannel[0]['mediatailor'];
            this.setState({ isMediatailorCreated });
        }
    };

    onChangeCalendarName(e) {
        let self = this;
        let catalogvalues = { ...self.state.formData };
        let name = e.target.name;
        let value = e.target.value;
        //console.log('value',value)

        // console.log('value aftewr==> ',k)

        let errors = Object.assign({}, this.state.errors);
        delete errors[name];
        catalogvalues[name] = value;
        this.setState({ [name]: value, formData: catalogvalues, errors });
        if (name == 'calendarId') {
            this.filterChannelByCode(value)
            let body = { "activity": "fetch", calendarId: value }
            this.props.manageScheduling(body);
        }


    }

    addSchedule = (e) => {
        let self = this;
        let formData = { ...self.state.formData }

        if (this.validate()) {
            let body;
            if (formData && formData._id) {
                formData["activity"] = "update";
                // formData["scheduleId"] = this.state.calendarId;
                // console.log('formData', formData)
                // // body = {
                // //     title: this.state.calendarName,
                // //     description: this.state.description || '',
                // //     landscape_logo_image: this.state.landscape_logo_image || siteConfig.defaultLandscape,
                // //     portrait_logo_image: this.state.portrait_logo_image || siteConfig.defaultPortrait,
                // //     logoImage: this.state.logoImage || siteSettings.logoImage,
                // //     activity: 'update'
                // // }
                // this.props.manageSchedules(formData);
                let id = formData._id;
                delete formData._id;
                //getFormData = self.state.getFormData
                // getFormData["channelname"] = self.state.channelname;
                // getFormData["serviceprovider"] = 'envoiPlaylistByUsers';
                formData["appname"] = window.app.appName;
                formData["parentAppName"] = window.app.appName;
                // console.log('else condition')
                self.props.manageChannels(formData, id);
                self.SuccessNotify();
                // self.setState({ custom: true, showMessage: "Updated Successfully" });

            } else {
                // formData["portrait_logo_image"] = formData.portrait_logo_image || siteConfig.defaultPortrait;
                // formData["landscape_logo_image"] = formData.landscape_logo_image || siteConfig.defaultLandscape;
                // formData["logoImage"] = formData.logoImage || siteSettings.logoImage;
                formData["activity"] = "create";
                // // body = {
                // //     title: this.state.calendarName,
                // //     description: this.state.description || '',
                // //     landscape_logo_image: formData.landscape_logo_image || siteConfig.defaultLandscape,
                // //     portrait_logo_image: formData.portrait_logo_image || siteConfig.defaultPortrait,
                // //     logoImage: formData.logoImage || siteSettings.logoImage,
                // //     activity: 'create'
                // // }
                // this.props.manageSchedules(formData);
                let siteSettings = window.site || {};
                let siteConfig = siteSettings.config || {};
                let email = window.localStorage.getItem("email");
                let firstname = window.localStorage.getItem("firstname");
                let lastname = window.localStorage.getItem("lastname");
                let userid = window.localStorage.getItem("user_id");
                let name = firstname + " " + lastname;
                formData["serviceprovider"] = "envoiSchedule";
                formData["portrait_logo_image"] = formData.portrait_logo_image || siteConfig.defaultPortrait;
                formData["landscape_logo_image"] = formData.landscape_logo_image || siteConfig.defaultLandscape;
                formData["logoImage"] = formData.logoImage || siteSettings.logoImage;
                formData["appname"] = window.app.appName;
                formData["parentAppName"] = window.app.appName;
                formData["status"] = "Inactive";
                formData["quickbooksProductType"] = "NonInventory";
                formData["contact"] = { email };
                formData["owner"] = { code: userid, name: name };
                self.props.manageChannels(formData);
                // self.props.manageChannels(getFormData, self.state.id);
                console.log('After updating getmetadat', formData)
                self.SuccessNotify();

                // self.setState({ custom: true, showMessage: "Updated Successfully" });

            }

            this.setState({ isAddScheduleOpen: false })
        }

    }

    validate() {
        let self = this;
        let isError = true;
        let errors = {};

        let formData = { ...self.state.formData };
        //console.log("this.state.calendarName", this.state.calendarName)
        let availability = formData['availability'] || { ...self.state.availability } || {};
        let buyplans = availability.buyplans || {};
        if (!availability || ((!availability['free']) &&
            (!availability['buyplans']) &&
            (!availability['publicViewing']))
        ) {
            // self.setState({ custom: true, showMessage: 'Please select Availability Plan' })
            self.errorToast('Please select Availability Plan')
            isError = false;
        }

        if (buyplans && buyplans.allow == true &&
            ((buyplans.monthly && buyplans.monthly.contentPrice && buyplans.monthly.contentPrice.planPrice <= 0) ||
                (buyplans.yearly && buyplans.yearly.contentPrice && buyplans.yearly.contentPrice.planPrice <= 0) ||
                (buyplans.buy && buyplans.buy.allow == true && !buyplans.buy.contentPrice))) {
            self.errorToast('Please Enter Price')
            isError = false;
        }
        if (formData && formData.channelname == undefined || formData.channelname == '') {
            isError = false;
            errors.channelname = "Name Required";
        }
        console.log("errors", errors)
        this.setState({ errors });
        return isError;
    }

    scheduleData() {
        let self = this;
        let id = this.state.calendarId
        let payload = {
            schedule: {
                events: self.state.activity == 'create' ? self.state.eventsData : this.state.calendarEvents
            }
        }
        this.props.scheduleEventsToS3(id, payload)
        this.setState({ isMetaDataReady: false })
    }

    SuccessNotify = () => toast.success('Updated Successfully', window.app.toastConfig)
    errorToast = (msg) => toast.error(msg, window.app.toastConfig)

    uploadS3(e, type) {
        var self = this;
        var fileData = new FormData();
        var file = e.target.files[0];
        var uploadFilePath = "";
        var filename = e.target.files[0].name;
        var s3file = e.target.files[0];
        var filekey = type[2];
        fileData.append(type[1], s3file);
        self.setState({ fileData: file, type, filekey })
        let appName = window.app.appName;
        if (self.state.formData && self.state.formData.channelname && !self.state.formData.channelname == "" || !self.state.formData.channelname == undefined || !self.state.formData.channelname == null) {
            var bucket;
            var imgHight;
            var imgWidth;

            if (type[1] == "Image") {
                if (type[0] == "1000*1294") {
                    imgHight = 1294;
                    imgWidth = 1000;
                } else if (type[0] == "1920*1080") {
                    imgHight = 1080;
                    imgWidth = 1920;
                }
                else if (type[0] == "3000*600") {
                    imgHight = 600;
                    imgWidth = 3000;
                }
                else if (type[0] == "1920*1080") {
                    imgHight = 1080;
                    imgWidth = 1920;
                }
                else if (type[0] == "175") {
                    //imgHight = 1080;
                    imgWidth = 610;
                }
                let height;
                let width;
                bucket = imageSourcebucket;
                var reader = new FileReader();
                reader.readAsDataURL(s3file);
                reader.onload = function (e) {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        height = this.height;
                        width = this.width;
                        let imagePathWidth;
                        if (imgWidth == "610") {
                            imagePathWidth = width <= imgWidth
                        } else {
                            imagePathWidth = width == imgWidth && height == imgHight
                        }
                        console.log("imagePathWidth[0]", width, height)
                        if (imagePathWidth) {
                            bucket = imageSourcebucket;
                            // if (width == imgWidth && height == imgHight) {
                            var path = "submission/partner";
                            let id = self.state.formData && self.state.formData._id;
                            let name = self.state.formData.channelname;
                            name = name && name.toLowerCase();
                            name = name && name.replace(/ /g, "-");
                            name = name && name.replace(/[^a-zA-Z 0-9]/g, "");
                            let folder = name;
                            uploadFilePath = appName + "/" + path + "/" + folder + "/" + removeSpecialCharecters(filename);
                            var data = { source_bucket: bucket, sourcepath: uploadFilePath }
                            self.setState({ uploadFilePath })
                            let siteSettings = window.site;
                            let siteConfig = siteSettings.config;
                            let imagePath = siteConfig && siteConfig.appResourcesUrl;
                            axios.post(envoiSubmissionApiBaseUrl + '/uploadURL?appname=' + appName + '&token=' + token, data, { type: 'application/json' })
                                .then((response) => {
                                    if (response.data && response.data.result) {
                                        var url = response.data.result;
                                        let config = {
                                            onUploadProgress: function (progressEvent) {
                                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                                self.setState({ [type[3]]: percentCompleted });
                                            }
                                        };
                                        axios.put(url, file, config, {
                                            "headers": {
                                                "Content-Type": "multipart/form-data",
                                                "Accept": "/",
                                                "Cache-Control": "no-cache",
                                                "Accept-Encoding": "gzip, deflate",
                                                "Connection": "keep-alive",
                                                "cache-control": "no-cache"
                                            }
                                        })
                                            .then((response) => {
                                                if (response && response.status === 200) {
                                                    var filename = self.state.uploadFilePath;
                                                    let imageUploadPath = imagePath + filename;

                                                    if (imageUploadPath.includes(appName + "/" + appName)) {
                                                        imageUploadPath = imageUploadPath.replace(appName + "/" + appName, appName);
                                                    }
                                                    self.SuccessNotify();
                                                    let obj = {
                                                        [self.state.filekey]: imageUploadPath
                                                    }
                                                    if (id) {
                                                        self.props.manageChannels(obj, id);
                                                    } else {
                                                        self.props.manageChannels(obj);
                                                    }
                                                    //  self.setState(obj);
                                                }
                                            })
                                            .catch((err) => {
                                                console.error.bind(err);
                                            })
                                    }
                                })
                                .catch((err) => {
                                    console.error.bind(err);
                                })
                            // } else {
                            //     self.setState({ addMetadata: true, alertMsg: 'Please select image(' + type[0] + ')' });
                            // }
                        } else {
                            let showAlertMsg = 'Please select image(' + type[0] + ')'
                            // self.errorToast(showAlertMsg);
                            self.setState({ addMetadata: true, alertMsg: 'Please select image(' + type[0] + ')' });
                        }
                    };
                }
            } else {
                bucket = assetSourcebucket;

            }
        } else {
            self.setState({ addMetadata: true, alertMsg: '"Please Enter (Title required)' });
        }
    }
    deleteImageFiles(e, item, type) {
        var self = this;
        let selectedType = { "imageType": type }
        this.setState({ assetFile: true, deleteItem: item, selectedType });
    }
    onCancelDelete() {
        this.setState({ assetFile: false, });
    }
    onConfirmDelete() {
        var self = this;
        let imageType = this.state.selectedType && this.state.selectedType.imageType ? this.state.selectedType.imageType : 'none'
        let siteSettings = window.site;
        if (self.state.deleteItem && self.state.deleteItem == "landscape_logo_image") {
            self.setState({ landscape_logo_image: undefined, })
            toast.success('Deleted Successfully', window.app.toastConfig)
        } else if (self.state.deleteItem && self.state.deleteItem == "portrait_logo_image") {
            self.setState({ portrait_logo_image: undefined })
            toast.success('Deleted Successfully', window.app.toastConfig)
        }
        else {
            self.setState({
                logoImage: '',
            })
            toast.success('Deleted Successfully', window.app.toastConfig)

        }
        self.setState({
            assetFile: false,
            [imageType]: 0
        });
    }


    handlePlayistByIdExpand(uuid) {
        let self = this;
        self.setState({ [uuid]: !self.state[uuid] });
    }

    playerTimeChanged = (currentSeektime) => {
        let self = this;
        self.setState({ currentSeektime: currentSeektime });
    }

    handleChange = (e) => {
        let self = this;
        const { start, end, extendedProps } = this.state.assetInfo;
        // console.log("extendedProps",extendedProps)
        let assetInfoData = this.state.assetInfoData;

        // console.log("assetInfoData ", assetInfoData)
        assetInfoData[e.target.name] = e.target.value;


        let convertedDateArray = extendedProps && extendedProps.formattedDuration && extendedProps.formattedDuration.split(' ');
        // console.log("convertedDateArray", convertedDateArray, e.target.value)
        convertedDateArray = convertedDateArray ? convertedDateArray : (this.state.duration && this.state.duration.split(' '));
        let hrs = convertedDateArray[0]
        hrs = parseInt(hrs.replace("h", ""));
        let mts = convertedDateArray[1]
        mts = parseInt(mts.replace("m", ""));
        let scnds = convertedDateArray[2]
        scnds = parseInt(scnds.replace("s", ""));
        var endDate;
        endDate = new Date(e.target.value);
        endDate.setSeconds(endDate.getSeconds() + scnds);
        endDate.setMinutes(endDate.getMinutes() + mts);
        endDate.setHours(endDate.getHours() + hrs);
        let endd = endDate;
        assetInfoData["end"] = moment(endd).format("YYYY-MM-DD[T]HH:mm:ss")
        // console.log("endd ", endd)
        //    let name = assetInfoData && assetInfoData.start && assetInfoData.start.split('T');
        //     let convertedDateArray = extendedProps && extendedProps.formattedDuration && extendedProps.formattedDuration.split(' ');
        //     console.log("convertedDateArray", convertedDateArray)
        //     let hrs = convertedDateArray[0]
        //     hrs = parseInt(hrs.replace("h", ""));
        //     let mts = convertedDateArray[1]
        //     mts = parseInt(mts.replace("m", ""));
        //     let scnds = convertedDateArray[2]
        //     scnds = parseInt(scnds.replace("s", ""));
        //     console.log("assetInfoData",assetInfoData)
        //     var endDate;
        //     endDate = name[1];
        //     // endDate.setSeconds(endDate.getSeconds() + scnds);
        //     // endDate.setMinutes(endDate.getMinutes() + mts);
        //     // endDate.setHours(endDate.getHours() + hrs);
        //     console.log("endDate",endDate)
        self.setState({ assetInfoData, isUpdateEnabled: true });
    }

    handleEndDateChange = (e) => {
        let self = this;
        let assetInfoData = this.state.assetInfoData;
        assetInfoData[e.target.name] = e.target.value;
        self.setState({ assetInfoData, isUpdateEnabled: true });
    }


    filterScheduleByUUID = (scheduleId) => {
        let self = this;
        const filteredSchedule = self.state.allSchedulesData.filter(
            (schedule) => ((schedule.extendedProps && schedule.extendedProps.scheduleUUID) === scheduleId)
        );
        if (filteredSchedule && filteredSchedule.length > 0) {
            let scheduleId = filteredSchedule[0]['_id']
            return scheduleId;
        }


    };

    updateScheduleData = () => {
        let self = this;
        // console.log("asset info....", this.state.assetInfo)
        const { start, end, extendedProps } = this.state.assetInfo;
        let assetInfoData = this.state.assetInfoData;
        let scheduleUUID = this.filterScheduleByUUID(extendedProps.scheduleUUID)
        let body = {
            start: assetInfoData.start ? assetInfoData.start : start,
            end: assetInfoData.end ? assetInfoData.end : end ? end : this.state.end,
            activity: "update",
            scheduleId: extendedProps.scheduleId ? extendedProps.scheduleId : scheduleUUID,
            calendarId: extendedProps.calendarId,
        }
        this.setState({ activity: 'update', }, () => {
            self.props.manageScheduling(body);
        })
    }

    deleteSchedule = () => {
        this.setState({ showDeleteEventAlert: true, })
    }

    onConfirmScheduleDelete = () => {
        let self = this;
        const { extendedProps } = this.state.assetInfo;
        let scheduleUUID = this.filterScheduleByUUID(extendedProps.scheduleUUID)
        let body = {
            activity: "delete",
            scheduleId: extendedProps.scheduleId ? extendedProps.scheduleId : scheduleUUID,
            calendarId: extendedProps.calendarId,
        }
        this.setState({ showDeleteEventAlert: false, isMetaDataReady: false, activity: 'delete' }, () => {
            self.props.manageScheduling(body);
        })

        this.state.assetInfo.remove();
    }

    createMediatailor() {
        let assetsData = this.props.manageSchedulingData || [];
        if (this.state.calendarId && assetsData.length > 0) {
            let payload = {
                channelCode: this.state.calendarId,
                // assetId: assetsData[0].extendedProps && assetsData[0].extendedProps.envoiAssetId,
            }
            this.props.createMediatailorChannel(payload)
        }
    }
    mediaTailorStartButton(e, mtType, ChannelName) {
        let self = this;
        self.setState({ loader: true });
        //let channelAssetData = self.state.partnerData || {};
        axios.post(envoiSubmissionApiBaseUrl + '/mtChannel?appname=' + window.app.appName + '&token=' + token + '&env=' + window.app.appEnvName + '&channelcode=' + this.state.calendarId + '&mtChannelName=' + ChannelName + '&mtType=' + mtType)
            .then((response) => {
                if (response && response.data && response.data.result) {
                    self.setState({ isMediatailorCreated: response.data.result, loader: false });
                }
            })
            .catch(error => error)
    }
    openSegmentPopup() {
        this.setState({ isSegmentPopup: true })
    }

    setStartOpen = ({ open }) => {
        let self = this;
        this.setState({ isStartOpen: open });
    };

    setEndOpen = ({ open }) => {
        let self = this;
        this.setState({ isEndOpen: open });
    };


    onChangeSegment(e) {
        const name = e.target && e.target.name;
        const value = e.target && e.target.value;
        let segment = this.state.segmentData;
        if (name == undefined) {
            const time = e;
            let startAt = time ? time.format('HH:mm:ss') : null;
            console.log("startAt", startAt)
            let durationStart = moment.duration(startAt)
            durationStart = durationStart._milliseconds / 1000;
            console.log("durationStart", durationStart)
            segment['segmentDuration'] = durationStart
            this.setState({ ['segmentDuration']: durationStart, segmentData: segment });
        } else {
            if (!!this.state.errors[name]) {
                let errors = Object.assign({}, this.state.errors);
                delete errors[name];
                segment[name] = value
                this.setState({
                    [name]: value,
                    segmentData: segment,
                    errors
                });
            } else {
                segment[name] = value
                this.setState({ [name]: value, segmentData: segment });
            }
        }
    }
    onChangeMarker(e) {
        const name = e.target.name;
        const value = e.target.value;

        let marker = this.state.markerData;
        if (!!this.state.errors[name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[name];
            marker[name] = value
            this.setState({
                [name]: value,
                markerData: marker,
                errors
            });
        } else {
            marker[name] = value
            this.setState({ [name]: value, markerData: marker });
        }
    }


    onStartTimeChange = (time) => {
        let self = this;
        const { duration } = this.state.currentSegment;
        const { durationInTime } = this.state;
        let errorsObj = { ...self.state.errors };
        let marker = this.state.markerData;
        let startAt = time ? time.format('HH:mm:ss') : null;
        let durationStart = moment.duration(startAt)
        durationStart = durationStart._milliseconds / 1000;
        marker["start"] = time;
        marker["seektime"] = durationStart;
        this.setState({ marker, errors: errorsObj })
    }

    onEndTimeChange = (time) => {
        let self = this;
        const { duration } = this.state.currentSegment;
        const { durationInTime } = this.state;
        let errorsObj = { ...self.state.errors };
        let marker = this.state.markerData;
        let endAt = time ? time.format('HH:mm:ss') : null;
        let durationEndAt = moment.duration(endAt)
        durationEndAt = durationEndAt._milliseconds / 1000;
        marker["end"] = time;
        marker["endtime"] = durationEndAt;
        this.setState({ marker, errors: errorsObj })
    }

    handleChangeComplete(color) {
        let self = this;
        let markerValue = color.hex;
        let markerData = Object.assign({}, this.state.markerData);
        markerData["markerColor"] = markerValue;
        self.setState({ markerData, markerColor: markerValue });
    };

    validateMarker() {
        let isError = false;
        const { title, duration, comment, end, start, VodSourceName, SourceLocationName } = this.state.markerData;
        let errors = {};

        if (title === '' || title === undefined) {
            isError = true;
            errors.title = "Enter Name";
        }
        if (duration === '' || duration === undefined) {
            isError = true;
            errors.duration = "Enter Duration";
        }

        if (comment === '' || comment === undefined) {
            isError = true;
            errors.comment = "Enter Comment";
        }

        if (start === '' || start === undefined) {
            isError = true;
            errors.start = "Enter start time";
        }
        if (end === '' || end === undefined) {
            isError = true;
            errors.end = "Enter end time";
        }
        if (VodSourceName === '' || VodSourceName === undefined) {
            isError = true;
            errors.VodSourceName = "Enter end time";
        }
        if (SourceLocationName === '' || SourceLocationName === undefined) {
            isError = true;
            errors.SourceLocationName = "Enter end time";
        }


        this.setState({ errors });
        return isError;
    };

    validateSegment() {
        let isError = false;
        let errors = {};
        const { title, duration } = this.state.segmentData;
        if (title === '' || title === undefined) {
            isError = true;
            errors.title = "Enter Name";
        }
        if (duration === '' || duration === undefined) {
            isError = true;
            errors.duration = "Enter Duration";
        }
        this.setState({ errors });
        return isError;
    };
    addSegment() {
        let self = this;
        let segment = this.state.segmentData || {};
        if (!this.validateSegment()) {
            let payload = {
                mode: 'insert',
                type: 'segment',
                title: segment.title,
                duration: segment.duration
            }
            let assetInfo = this.state.assetInfo || {}
            let assetId = assetInfo.id ? assetInfo.id : (assetInfo.extendedProps || {}).envoiAssetId;
            self.props.inserAssetMarkers(assetId, payload);
            this.setState({ isSegmentPopup: false, isSementsLoading: true })

        }
    }

    collapseAndExpand = (index) => {
        this.setState({ ['isExpand' + index]: !this.state['isExpand' + index] })
    }

    openMarkerPopup = (segment) => {
        console.log("segment", segment)
        // let formattedDuration = segment.duration && moment.duration(segment.duration, 'seconds');
        // let durationAt = formattedDuration && formattedDuration.format("HH:mm:ss", { trim: false });
        // durationAt = durationAt && moment(durationAt, 'HH:mm:ss');
        // durationAt = durationAt && durationAt._i;
        this.setState({ isMarkerPopup: true, segmentId: segment._id, currentSegment: segment })
    }

    addMarker() {
        let self = this;
        let marker = this.state.markerData || {};
        if (!this.validateMarker()) {
            let payload = {
                mode: 'insert',
                title: marker.title,
                seektime: this.state.currentSeektime || marker.seektime,
                start: marker.seektime,
                end: marker.endtime || "1",
                duration: marker.duration,
                comment: marker.comment,
                segmentId: this.state.segmentId
            }
            let assetInfo = this.state.assetInfo || {}
            let assetId = assetInfo.id ? assetInfo.id : (assetInfo.extendedProps || {}).envoiAssetId;
            self.props.inserAssetMarkers(assetId, payload);
            this.setState({ isMarkerPopup: false, isSementsLoading: true })
        }
    }

    deleteSegmentORMarker = (id, type) => {
        this.setState({ showDeletePopup: true, deleteMarkerId: id, deleteMarkerType: type }, () => {

        })
    }

    onConfirmDeleteMarker() {
        let payload = {
            mode: "delete",
            markerid: this.state.deleteMarkerId
        }
        let assetInfo = this.state.assetInfo || {}
        let assetId = assetInfo.id ? assetInfo.id : (assetInfo.extendedProps || {}).envoiAssetId;
        this.props.deleteAssetMarkers(assetId, payload);
        this.setState({ showDeletePopup: false })
    }


    async handleFiles(event) {
        var self = this;
        var file = event[0];
        var fileExt = file.name.split('.').pop();
        var reader = new FileReader();
        reader.onload = function (event) {
            let assetsData;
            let data = event.target.result;
            switch (fileExt) {
                case 'csv':
                    csv({})
                        .fromString(data)
                        .then((csvRow) => {
                            assetsData = csvRow;
                            self.setState({ loader: true, fileType: fileExt });
                            self.handleDisplay(assetsData)
                        })
                    break;
                case 'xlsx':
                case 'xls':
                    let workbook = XLSX.read(data, { type: "binary" });
                    workbook.SheetNames.forEach(sheet => {
                        assetsData = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
                        self.setState({ loader: true, fileType: fileExt });
                        self.handleDisplay(assetsData)
                    });
                    break;
                case 'json':
                    console.log("data", data)
                    assetsData = data;
                    self.setState({ loader: true, fileType: fileExt });
                    self.handleDisplay(assetsData)
                    break;
            }
        };
        if (fileExt == 'json') {
            reader.readAsText(file);
        } else {
            reader.readAsBinaryString(file);
        }
    }


    handleDisplay(assetsList) {
        let self = this;
       //console.log("assetsList", assetsList)
        self.setState({ assetsList, loader: false });
        let importAsset = JSON.stringify(assetsList)
        //console.log("importAsset", importAsset)
        if (assetsList && assetsList.length > 0) {
            let eventList = [];
            // let startDateEvent = start;
            assetsList.map((element, i) => {
                let obj = Object.assign({});
                obj = {
                    title: element.title,
                    start: element.start,
                    end: element.end,
                    formatedduration: element.formatedduration || 0,
                    classNames: ['standaloneAsset'],
                    extendedProps: {
                        envoiAssetId: element.assetid,
                        scheduleUUID: element.scheduleId,
                    },
                    durationEditable: false,
                    activity: "create",
                    type: 'Scheduler',
                    calendarId: this.state.calendarId,
                    channelid: this.state.calendarId,
                    assetid: element.assetid
                };
                
                eventList.push({ ...obj })
            })

            let eventsApi = {
                events: eventList
            }

            console.log('eventsApi', eventsApi)

            //     events = events[events]
            //   ]
            let token = appStorage.getItem('token');
            let calederId = this.state.calendarId || "";
            let scheduleId = this.state.scheduleUUID || "";
            let url = envoiSubmissionApiBaseUrl + "/scheduleimport?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token
            calederId ? (url += `&calederId=${calederId}`) : null;
            scheduleId ? (url += `&scheduleId=${scheduleId}`) : null;

            axios.post(url, eventsApi, { type: 'application/json' })
                .then((response) => {
                    if (response.data && response.data.statusCode == "200") {
                        let body = { "activity": "fetch", calederId }
                        this.props.manageScheduling(body);
                    }
                })

                .catch((err) => {
                    console.error.bind("reducer.js embedLinksData", { err });
                })
        }

    }

    render() {
        let self = this;
        const { currentSeektime, seektime, updateForm, assetData, schedulesData, recordingAssets, isLoading, noOfAssets, assetTotal, loadbtn, assetInfo, noOfPlaylists, playlistTotal, assetInfoData, isUpdateEnabled, markerData, segmentData, isMediatailorCreated } = self.state;
        const { genre, carousel, maturityrating, released, tags, cast, crew, writer, distributer, award, studio } = assetData
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;
        // let fullTilte = assetInfo && assetInfo.title && assetInfo.title.split('-');
        let eventTitle = assetInfo && assetInfo.title
        let runtime = assetInfo && assetInfo.extendedProps && assetInfo.extendedProps.playlistData && assetInfo.extendedProps.playlistData.totalduration
        let startDate;
        let endDate;
        if (isUpdateEnabled && assetInfoData) {
            startDate = moment((assetInfoData && assetInfoData.start) ? assetInfoData.start : assetInfo && assetInfo.start ? assetInfo.start : self.state.start).format("YYYY-MM-DD[T]HH:mm:ss");
            endDate = moment((assetInfoData && assetInfoData.end) ? assetInfoData.end : assetInfo && assetInfo.end ? assetInfo.end : self.state.end).format("YYYY-MM-DD[T]HH:mm:ss");
        } else {
            startDate = moment(assetInfo && assetInfo.start ? assetInfo.start : self.state.start).format("YYYY-MM-DD[T]HH:mm:ss");
            endDate = moment(assetInfo && assetInfo.end ? assetInfo.end : self.state.end).format("YYYY-MM-DD[T]HH:mm:ss");
        }
        //  console.log('mediatailorData',self.state.isMediatailorCreated)
        return (
            <React.Fragment>
                <Header />
                <div className="animated fadeIn demo-app scheduling">
                    <div className='top_bar'>
                        <div className='top_bar_wrp'>
                            <div className='dsp_flex'>
                                <div className='select_chnl'>
                                    <select className="caleder_select" value={this.state.calendarId} placeholder="Calendar" name="calendarId" onChange={e => this.onChangeCalendarName(e)}>
                                        <option >SELECT SCHEDULE</option>
                                        {schedulesData && schedulesData.length > 0 && schedulesData.map(function (schedule, i) {
                                            return (
                                                <option key={i} value={schedule.channelcode}>{schedule.channelname}</option>
                                            )
                                        })}
                                    </select>
                                    <span className="material-icons arrow"> arrow_drop_down </span>
                                </div>
                                <button onClick={(e) => this.addScheduleOpen(e)} className="add"><span className="material-symbols-outlined">
                                    add
                                </span>Add</button>
                                {self.state.calendarId &&
                                    <button onClick={(e) => this.editScheduleOpen(e)} className="add"><span className="material-symbols-outlined">
                                        edit
                                    </span>Edit</button>}

                            </div>
                            {isMediatailorCreated &&
                                <button type="button" className="btn btn-primary" onClick={e => self.mediaTailorStartButton(e, isMediatailorCreated && isMediatailorCreated.ChannelState == "STOPPED" ? "start" : "stop", isMediatailorCreated.ChannelName)}> {isMediatailorCreated && isMediatailorCreated.ChannelState == "STOPPED" ? "Start" : "Stop"}</button>}
                        </div>
                    </div>

                    <div className='fc_col'>
                        <div
                            id="external-events"
                            style={{
                                padding: "10px",
                                width: "80%",
                                height: "auto",
                                maxHeight: "-webkit-fill-available"
                            }}
                        >
                            <CardHeader className="sub_tab">
                                <Nav className="nav-fill card-header-tabs" tabs>
                                    <NavItem>
                                        <NavLink
                                            className={self.state.activeTab === '1' ? 'active' : ''}
                                            onClick={() => {
                                                this.toggle('1');
                                            }}>
                                            Media
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={self.state.activeTab === '2' ? 'active' : ''}
                                            onClick={() => {
                                                this.toggle('2');
                                            }}>
                                            Playlists
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <TabContent activeTab={self.state.activeTab}>
                                <TabPane tabId="1" >
                                    <CardBody>



                                        <div className="lf_search">
                                            <input type="search" className="input" name='recodingsSearchInput' onChange={e => this.searchOnChange(e)} onKeyDown={(e) => this.handleKeyDown(e, 'recordings')}
                                                value={this.state.recodingsSearchInput}
                                                placeholder="Search"
                                            />

                                            <button type="button" className="icon" onClick={() => self.searchForAssets('recordings')} ><span className="material-icons">
                                                search
                                            </span></button>
                                        </div>
                                        {this.props.loading && <Loader />}
                                        {!this.props.loading &&
                                            recordingAssets &&
                                            Array.isArray(recordingAssets) &&
                                            recordingAssets &&
                                            recordingAssets.length > 0 ? (
                                            recordingAssets.map((event, i) => (
                                                imgUrl = event.imageurl,
                                                eventFormatDuration = event.totalduration ? event.totalduration : '',
                                                <div
                                                    className="fc-event drag_item"
                                                    title={event && event.title}
                                                    data={event && event.id}
                                                    data-for={JSON.stringify(event)}
                                                    key={i}
                                                >
                                                    <div className='img'>
                                                        <img src={event && event.imageurl ? event.imageurl + siteConfig.imageSuffix : ""} />
                                                    </div>

                                                    <div className='channel_title'>
                                                        {event && event.title}
                                                    </div>

                                                    <div className='sdl_duration'>{eventFormatDuration}</div>
                                                </div>
                                            ))

                                        ) : (
                                            isLoading ? <Loader /> :
                                                <React.Fragment>
                                                    <div className="warning-wrapper empty-result">
                                                        <div className="error-text">
                                                            <h2 className="text-center">Sorry !</h2>
                                                            <p>There are no assets available</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                        )}
                                        {noOfAssets < assetTotal && (
                                            <div className="text-center clearfix">
                                                <div
                                                    onClick={() => this.loadMore(undefined, false)}
                                                    className="btn_load"
                                                    type="button"
                                                    id="myButton"
                                                    value=" "
                                                >
                                                    {loadbtn}
                                                </div>
                                            </div>
                                        )}
                                    </CardBody>

                                </TabPane>
                                <TabPane tabId="2" >
                                    <CardBody>

                                        <div className="lf_search">
                                            <input type="search" className="input" name='playlistSearchInput' onChange={e => this.searchOnChange(e)} onKeyDown={(e) => this.handleKeyDown(e, 'playlists')}
                                                value={this.state.playlistSearchInput}
                                                placeholder="Search"
                                            />
                                            <button type="button" className="icon" onClick={() => self.searchForAssets('playlists')} ><span className="material-icons">
                                                search
                                            </span></button>
                                        </div>
                                        {this.state.events && this.state.events.map((event, i) => (
                                            imgUrl = event.imageurl,

                                            <div key={i}>
                                                <div className=" fc-event drag_item  pl_item" title={event && event.title} data={event && event.id}
                                                    data-for={JSON.stringify(event)} >
                                                    <div className='icon' onClick={() => this.handlePlayistByIdExpand(event.uuid)}><span style={{ cursor: 'pointer' }} className="material-icons">{self.state[event.uuid] ? 'remove_circle_outline' : 'add_circle_outline'}</span></div>

                                                    <div className="img">
                                                        <img src={event && event.imageurl ? event.imageurl + siteConfig.imageSuffix : ""} />
                                                    </div>
                                                    <div className="channel_title">{event && event.title}</div>

                                                </div>
                                                {self.state[event.uuid] && <div className='pl_sub'>
                                                    {event && event.assets.map((asset, j) => (
                                                        eventFormatDuration = asset.totalduration ? asset.totalduration : asset.formatedduration,
                                                        <div key={j} className="fc-event drag_item pl_item"
                                                            title={asset.assetname}
                                                            data={asset && asset.id}
                                                            data-for={JSON.stringify(asset)}
                                                        >
                                                            <div className='icon'>
                                                                {/* <span assName`?="material-icons"> add_circle_outline</span> */}
                                                            </div>
                                                            <div className="img">
                                                                <img src={asset && asset.landscape_logo_image ? asset.landscape_logo_image + siteConfig.imageSuffix : ""} />
                                                            </div>
                                                            <div className="channel_title">{asset.assetname}</div>
                                                            <div className="sdl_duration">{asset.formatedduration}</div>
                                                        </div>))}
                                                </div>}
                                            </div>
                                        ))}
                                        {noOfPlaylists < playlistTotal && (
                                            <div className="text-center clearfix">
                                                <div
                                                    onClick={() => this.loadMorePlaylist(undefined, false)}
                                                    className="btn_load"
                                                    type="button"
                                                    id="myButton"
                                                    value=" "
                                                >
                                                    {loadbtn}
                                                </div>
                                            </div>
                                        )}
                                    </CardBody>
                                </TabPane>
                            </TabContent>
                        </div>
                        <div className="demo-app-calendar" id="mycalendartest">
                            <DateRangePicker onChange={this.calederDataRange} />
                            <FullCalendar
                                // timeZone='America/New_York'
                                id='fullCalendar'
                                defaultView="dayGridMonth"
                                header={{
                                    left: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                                    center: "title",
                                    right: "prev,next today"

                                }}
                                rerenderDelay={10}
                                eventDurationEditable={false}
                                // eventTimeFormat={{
                                //     hour: '2-digit',
                                //     minute: '2-digit',
                                //     // second: '2-digit',
                                //     hour12: false
                                // }}
                                eventTimeFormat={{
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hourCycle: 'h23'
                                }}

                                editable={true}
                                droppable={true}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                ref={this.calendarComponentRef}
                                weekends={this.state.calendarWeekends}
                                events={this.state.calendarEvents}
                                // eventDragStop={this.remove}
                                // select={this.add}
                                eventDrop={this.eventDrop}
                                eventReceive={this.eventReceive}
                                eventClick={this.eventClick}
                                validRange={{ start: new Date() }}
                                allDay={false}
                                //allDaySlot={false}
                                eventOrder={true}
                                eventOverlap={true}

                            />
                            {(self.state.calendarId && self.state.allSchedulesData && self.state.allSchedulesData.length > 0) && <div className="cl_buttons">

                                <button type="button" className="btn btn-primary" onClick={() => self.createMediatailor()}>{this.state.isMediatailorCreated ? 'Update Channel' : 'Create Channel'}</button> &nbsp;&nbsp;
                                <ReactFileReader handleFiles={e => this.handleFiles(e)} fileTypes={[".xlsx", ".xls", ".csv", ".json"]}>
                                    <button type="button" className="btn btn-primary" ><span>Import Schedule</span></button>
                                </ReactFileReader>
                                <button type="button" className="btn btn-primary" onClick={e => self.scheduleData(e)} disabled={self.state.executing}><span>{self.state.submitText ? "Saving..." : "Export Schedule"}</span></button>&nbsp;&nbsp;&nbsp;



                                {/* {isMediatailorCreated && 
                                    <button type="button" className="jr-btn btn btn-primary" onClick={e => self.mediaTailorStartButton(e, isMediatailorCreated && isMediatailorCreated.ChannelState == "STOPPED" ? "start" : "stop", isMediatailorCreated.ChannelName)}> {isMediatailorCreated && isMediatailorCreated.ChannelState == "STOPPED" ? "Start" : "Stop"}</button>} */}

                            </div>}
                        </div>
                        {/* <Modal
                            isOpen={this.state.isOpen}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={() => { this.setState({ isOpen: false }) }}
                            shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup">
                            <div className='metadata'>
                                <div className='meta_left'>
                                    <div className='section'>
                                        <h3 className='title'>{assetData.assetname}</h3>
                                        <p>{assetData.description}</p>
                                        <h4 className='mt-10 mb-0'>Asset Duration <span>{assetData.totalduration || '--'}</span></h4>
                                    </div>
                                    <div className='section border_n'>
                                        <h4>Type {carousel && carousel.length > 0 ? carousel.map((item, i) => (<span key={i}>{item.name || 'NA'},</span>)) : <span>NA</span>}</h4>
                                        <h4>Genre {genre && genre.length > 0 ? genre.map((item, i) => (<span key={i}>{item || 'NA'},</span>)) : <span>NA</span>}</h4>
                                        <h4>Release Date <span>{released || '--'}</span></h4>
                                        <h4>Rating  <span>{maturityrating || 'NA'}</span></h4>
                                    
                                    </div>
                                </div>
                                <div className='meta_right'>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Metadata</a>
                                        </li>
                                     
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div className="inputgroup">
                                                <p className="label">Cast</p>
                                                {cast && <SelectionsExample options={[]} className="custom-select form-control" selected={cast || []} placeholder='Cast' labelKey='cast' isView={true} />}
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Crew</p>
                                                {crew && <SelectionsExample options={[]} className="custom-select form-control" selected={crew || []} placeholder='Crew' labelKey='crew' isView={true} />}
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Writer</p>
                                                {writer && <SelectionsExample options={[]} className="custom-select form-control" selected={writer || []} placeholder='Writer' labelKey='item' isView={true} />}
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Distributer</p>
                                                {distributer && <SelectionsExample options={[]} className="custom-select form-control" selected={distributer || []} placeholder='Distributer' labelKey='item' isView={true} />}
                                            </div>

                                            <div className="inputgroup">
                                                <p className="label">Awards</p>
                                                {award && <SelectionsExample options={[]} className="custom-select form-control" selected={award || []} placeholder='Awards' labelKey='item' isView={true} />}
                                            </div>

                                            <div className="inputgroup">
                                                <p className="label">Studio</p>
                                                {studio && <SelectionsExample options={[]} className="custom-select form-control" selected={studio || []} placeholder='Studio' labelKey='item' isView={true} />}
                                            </div>

                                            <div className='inputgroup'>
                                                <p className="label"><span>Tags</span></p>
                                                {tags && <SelectionsExample options={[]} className="custom-select form-control" selected={tags || []} placeholder='Tags' labelKey='item' isView={true} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal> */}
                        <Modal
                            isOpen={this.state.isAddScheduleOpen}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={() => { this.setState({ isAddScheduleOpen: false }) }}
                            shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup">
                            <div className='popup add-channel'>
                                <div className='popup_hed'>
                                    <h2>{self.state.formData && self.state.formData._id ? "EDIT SCHEDULE" : "ADD SCHEDULE"}</h2>
                                    <a href="#" onClick={() => { this.setState({ isAddScheduleOpen: false }) }} className='close material-symbols-outlined'>close </a>
                                </div>
                                <div className='popup_content'>
                                    <div className="add_calender">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='inputgroup'>
                                                    <p className='label'>Title</p>
                                                    <input type="text" placeholder='Enter Name' label='channel-title' name="channelname" value={self.state.formData && self.state.formData.channelname} onChange={e => self.onChangeCalendarName(e)} />
                                                    <span className="errormsg">{this.state.errors.channelname}</span>
                                                </div>
                                                <div className='inputgroup'>
                                                    <p className='label'>Description</p>
                                                    <textarea className='channel_description' rows={"4"} placeholder="Description" name="description" value={self.state.formData && self.state.formData.description} onChange={e => self.onChangeCalendarName(e)}></textarea>
                                                </div>
                                                <div className="inputgroup">
                                                    <p className='label'>Channel Type</p>
                                                    <select className="custom-select form-control" name="type" value={self.state.formData && this.state.formData.type} onChange={e => this.onChangeCalendarName(e)}>
                                                        <option value=" ">Select</option>
                                                        <option value="BASIC">Basic</option>
                                                        <option value="STANDARD">Standard</option>
                                                    </select>

                                                </div>
                                                <div className="inputgroup">
                                                    <p className='label'>Status</p>
                                                    <select className="custom-select form-control" name="status" value={self.state.formData && self.state.formData.status} onChange={e => self.onChangeCalendarName(e)}>
                                                        <option value="">Select</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </select>

                                                </div>

                                            </div>
                                            <div className='col-md-12'>
                                                <h3 className='sub_title'>Thumbnail</h3>
                                                <div className='uploadPics'>
                                                    <div className='upload'>
                                                        <div className="img_block">
                                                            <img src={self.state.formData && self.state.formData.landscape_logo_image ? self.state.formData.landscape_logo_image + siteConfig.imageSuffix : siteConfig.defaultLandscape + siteConfig.imageSuffix} />
                                                            <div className="upload_btns">
                                                                <div className="btn"><input type="file" accept="image/png, image/jpeg , image/*" name="landscape_logo_image" className="udisplay-none" onChange={self.state.landscape_logo_image ? e => self.uploadS3(e, ["1920*1080", "Image", "landscape_logo_image", "landscapeLogoProgress"]) : e => self.uploadS3(e, ["1920*1080", "Image", "landscape_logo_image", "landscapeLogoProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                    {"Upload"}
                                                                </div>
                                                                {self.state.formData && self.state.formData.landscape_logo_image ? <a href="javascript:void(0)" className="uprog-delt"><span className="material-icons" onClick={e => self.deleteImageFiles(e, "landscape_logo_image", "landscapeLogoProgress")}>delete</span> </a> : null}
                                                            </div>
                                                        </div>
                                                        <div className="size">Landscape  (1920*1080)</div>


                                                    </div>
                                                    <div className='upload portrait'>
                                                        <div className="img_block u-potrait">
                                                            <img className="portait_img" src={self.state.formData && self.state.formData.portrait_logo_image ? self.state.formData.portrait_logo_image + siteConfig.imageSuffix : siteConfig.defaultPortrait + siteConfig.imageSuffix} />
                                                            <div className="upload_btns">
                                                                <div className="btn"><input type="file" accept="image/png, image/jpeg , image/*" name="portrait_logo_image" className="udisplay-none " onChange={self.state.portrait_logo_image ? e => self.uploadS3(e, ["1000*1294", "Image", "portrait_logo_image", "portraitLogoProgress"]) : e => self.uploadS3(e, ["1000*1294", "Image", "portrait_logo_image", "portraitLogoProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                    {"Upload"}
                                                                </div>
                                                                {self.state.formData && self.state.formData.portrait_logo_image ? <a href="javascript:void(0)" className="uprog-delt"><span className="material-icons" onClick={e => self.deleteImageFiles(e, "portrait_logo_image", "portraitLogoProgress")}>delete</span></a> : null}
                                                            </div>
                                                        </div>
                                                        <div className="size">Portrait (1000*1294)</div>
                                                    </div>
                                                    <div className='upload up-logo'>
                                                        <div className="img_block u-potrait u-bg-black">
                                                            <img className="new-user-logo-u" src={self.state.formData && self.state.formData.logoImage ? self.state.formData.logoImage + siteConfig.imageSuffix : siteSettings.logoImage + siteConfig.imageSuffix} />
                                                            <div className="upload_btns pt-15">
                                                                <div className="btn"><input type="file" accept="image/png, image/jpeg , image/*" name="logoImage" className="udisplay-none " onChange={self.state.logoImage ? e => self.uploadS3(e, ["175", "Image", "logoImage", "logoImageProgress"]) : e => self.uploadS3(e, ["175", "Image", "logoImage", "logoImageProgress"])} onClick={(e) => { e.target.value = '' }} />
                                                                    {"Upload"}
                                                                </div>
                                                                {self.state.formData && self.state.formData.logoImage ? <a href="javascript:void(0)" className="uprog-delt"><span className="material-icons" onClick={e => self.deleteImageFiles(e, "logoImage", "logoImageProgress")}>delete</span><i  ></i></a> : null}

                                                            </div>
                                                        </div>
                                                        <div className="size">Logo</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <input type="text" className="input" name='calendarName' onChange={e => this.onChangeCalendarName(e)} value={this.state.calendarName} placeholder="Enter Name" />
                                        <span className="errormsg" >{this.state.errors.calendarName}</span> */}
                                        <div className='panel_content mb-5'>

                                            {/* <h1>kiran</h1> */}
                                            <Availability
                                                handleAvailability={self.handleAvailability} availability={self.state.availability}
                                                donation={self.state.donation} voting={self.state.voting} likeDislike={self.state.likeDislike}
                                                embed={self.state.embed} preview={self.state.preview} timezoneData={self.state.timezoneData} formData={self.state.formData}
                                                assetType={self.state.assetType} assetid={self.state.assetid ? self.state.assetid : ""} serviceProvider={self.state.serviceProvider} />
                                        </div>

                                        <div className='footer_btn'>
                                            <button type="button" className="add" onClick={e => self.addSchedule(e)} > <span className="material-symbols-outlined">add</span> {self.state.formData && self.state.formData._id ? "Update" : "Add"}</button>
                                        </div>

                                    </div>

                                </div>
                                <SweetAlert show={this.state.addMetadata}
                                    custom
                                    confirmBtnText="Ok"
                                    confirmBtnBsStyle="primary"
                                    title={this.state.alertMsg}
                                    onConfirm={e => self.setState({ alertMsg: '', addMetadata: false })}
                                >
                                </SweetAlert>
                                <SweetAlert show={this.state.assetFile}
                                    custom
                                    showCancel
                                    confirmBtnText="Ok"
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="default"
                                    title={"Are you sure to delete this Asset File ?"}
                                    onConfirm={e => this.onConfirmDelete()}
                                    onCancel={e => this.onCancelDelete()}
                                >
                                </SweetAlert>
                            </div>

                        </Modal>
                        <SweetAlert show={this.state.showAlert}
                            custom
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title={self.state.errorMsg}
                            onConfirm={e => self.setState({ errorMsg: '', showAlert: false })}
                        >
                        </SweetAlert>

                        <SweetAlert show={this.state.showDeleteEventAlert}
                            custom
                            showCancel
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title="Are you sure want to delete this schedule ?"
                            onConfirm={e => self.onConfirmScheduleDelete(e)}
                            onCancel={e => self.setState({ showDeleteEventAlert: false })}
                        >
                        </SweetAlert>


                        <SweetAlert show={this.state.showDeletePopup}
                            custom
                            showCancel
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title={"Are you sure want to delete this " + this.state.deleteMarkerType}
                            onConfirm={e => self.onConfirmDeleteMarker()}
                            onCancel={e => self.setState({ showDeletePopup: false })}
                        >
                        </SweetAlert>

                        {/*-------------------- Add Segment -------------------- */}

                        <Modal
                            isOpen={this.state.isSegmentPopup}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={() => { this.setState({ isSegmentPopup: false }) }}
                            // shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup">
                            <div className='popup add-channel add-Segment'>
                                <div className='popup_hed'>
                                    <h2> Add Segment</h2>
                                    <a href="#" onClick={() => { this.setState({ isSegmentPopup: false }) }} className='close material-symbols-outlined'>close </a>
                                </div>
                                <div className='popup_content'>
                                    <div className="add_calender">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='inputgroup'>
                                                    <p className='label'>Segment Name</p>
                                                    <input type="text" placeholder='Enter Name' name='title' value={segmentData.title} onChange={(e) => this.onChangeSegment(e)} />
                                                    <span className="errormsg">{this.state.errors.title}</span>
                                                </div>

                                                <div className="inputgroup">
                                                    <p className='label'>Duration (In Seconds)</p>
                                                    {/* <TimePicker id="duration" name='segmentDuration' value={segmentData.segmentDuration} onChange={e => this.onChangeSegment(e)} placeholder="Duration" open={this.state.isDurationOpen} onOpen={this.setDurationOpen} onClose={this.setDurationOpen} focusOnOpen /> */}
                                                    <input type="number" name="duration" value={segmentData.duration} onChange={e => this.onChangeSegment(e)} placeholder="Duration" />
                                                    <span className="errormsg">{this.state.errors.duration}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='footer_btn'>
                                            <button type="button" className="add" onClick={e => self.addSegment(e)} > <span className="material-symbols-outlined">add</span> {"Add"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        {/* -------------------- Add Marker -------------------- */}
                        <Modal
                            isOpen={this.state.isMarkerPopup}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={() => { this.setState({ isMarkerPopup: false }) }}
                            // shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup">
                            <div className='popup add-channel add-Segment'>
                                <div className='popup_hed'>
                                    <h2> Add Marker</h2>
                                    <a href="#" onClick={() => { this.setState({ isMarkerPopup: false }) }} className='close material-symbols-outlined'>close </a>
                                </div>
                                <div className='popup_content'>
                                    <div className="add_calender">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Title</p>
                                                    <input type="text" placeholder="Name" name="title" value={markerData.title} onChange={e => this.onChangeMarker(e)} />
                                                    <span className="errormsg">{this.state.errors.title}</span> </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Duration (In Seconds)</p>
                                                    <input type="number" name="duration" value={markerData.duration} onChange={e => this.onChangeMarker(e)} />
                                                    <span className="errormsg">{this.state.errors.duration}</span>

                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Start Time</p>
                                                    <TimePicker id="start" name='start' value={markerData.start}
                                                        // onChange={e => this.onChangeMarker(
                                                        //     {
                                                        //         target: {
                                                        //             name: 'start', value: e
                                                        //         }
                                                        //     }
                                                        // )} 
                                                        onChange={e => this.onStartTimeChange(e)}
                                                        placeholder="Start" open={this.state.isStartOpen} onOpen={this.setStartOpen} onClose={this.setStartOpen} focusOnOpen />
                                                    {/* <input type="text" placeholder={currentSeektime ? null : "Player Initializing..."} name="start" value={updateForm ? this.props.msTimeMilliseconds(seektime) : currentSeektime ? this.props.msTimeMilliseconds(currentSeektime) : "00.00.00"} onChange={e => this.onChangeMarker(e)} /> */}
                                                    <span className="errormsg">{this.state.errors.start}</span>
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">End Time</p>
                                                    <TimePicker id="end" name='end' value={markerData.end}

                                                        // onChange={e => this.onChangeMarker({
                                                        //     target: {
                                                        //         name: 'end', value: e
                                                        //     }
                                                        // })} 
                                                        onChange={e => this.onEndTimeChange(e)}

                                                        placeholder="End" open={this.state.isEndOpen} onOpen={this.setEndOpen} onClose={this.setEndOpen} focusOnOpen />
                                                    {/* <input type="text" placeholder={currentSeektime ? null : "Player Initializing..."} name="end" value={updateForm ? this.props.msTimeMilliseconds(seektime) : currentSeektime ? this.props.msTimeMilliseconds(currentSeektime) : "00.00.00"} onChange={e => this.onChangeMarker(e)} /> */}
                                                    <span className="errormsg">{this.state.errors.end}</span>
                                                </div>

                                            </div>
                                            <div className='col-md-12'>
                                                <div className="inputgroup">
                                                    <p className="label">Comments</p>
                                                    <textarea rows="2" cols="50" name="comment" value={markerData.comment} placeholder="Add Comment" onChange={e => this.onChangeMarker(e)} ></textarea>
                                                    <span className="errormsg">{this.state.errors.comment}</span>
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Source Location Name</p>
                                                    <select id="SourceLocationName" name="SourceLocationName" value={markerData.SourceLocationName} onChange={e => self.onChangeMarker(e)}>
                                                        <option value="">Select</option>
                                                        {this.props.sourceLocationData && [this.props.sourceLocationData].length > 0 && [this.props.sourceLocationData].map((item, index) => { return (<option key={index} value={item}>{item}</option>) })}
                                                    </select>
                                                    <span className="errormsg">{this.state.errors.SourceLocationName}</span>
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="inputgroup">
                                                    <p className="label">Vod Source Name</p>
                                                    {/* <input type="text" name="VodSourceName" value={markerData.VodSourceName} onChange={e => this.onChangeMarker(e)} /> */}
                                                    <select id="VodSourceName" name="VodSourceName" value={markerData.VodSourceName} onChange={e => self.onChangeMarker(e)}>
                                                        <option value="">Select</option>
                                                        {this.state.vodSourcesData && this.state.vodSourcesData.length > 0 && this.state.vodSourcesData.map((item, index) => {
                                                            return (<option key={index} value={item.VodSourceName}>{item.assetname}</option>)
                                                        })}


                                                    </select>
                                                    <span className="errormsg">{this.state.errors.VodSourceName}</span>
                                                </div>

                                            </div>
                                            {/* <div className='col-md-12'>
                                            <h3 className='sub_title'>Options</h3>
                                            <label className="font-white">Marker Color </label>
                                            <div className="u-checkmark">
                                                <CompactPicker
                                                    color={this.state.markerColor}
                                                    onChangeComplete={()=>this.handleChangeComplete()}
                                                />

                                                <span className="errormsg">{this.state.errors.markerColor}</span>
                                            </div>
                                        </div> */}
                                        </div>
                                        <div className='footer_btn'>
                                            <button type="button" className="add" onClick={e => self.addMarker(e)} > <span className="material-symbols-outlined">add</span> {"Add"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        {self.state.isMetaDataReady && <div className='side_popup pd' style={{ zIndex: '1111' }}>
                            <div className="popup_hed"><h2>Program details</h2><a href="#" onClick={e => self.setState({ isMetaDataReady: false })} className="close material-symbols-outlined">close </a></div>
                            <div className='popup_content'>
                                <div className='pd_block'>
                                    <div className="field">
                                        <label className='dsp_flx'>{eventTitle ? eventTitle : assetInfo.title}  </label>
                                        <p>{assetInfo.extendedProps.description}</p>
                                        {/* <p className='dsp_flx'>Jessy_Terrero<span className="material-icons"> content_copy </span></p> */}
                                    </div>
                                    <div className="field">
                                        {/* <a href="#" className='icon_flx'>
                                            <span className="material-icons"> calendar_month </span> <span className="edit"> Sun, Apr 2, 2023 <span className="material-icons"> edit </span></span>
                                        </a>
                                        <div className='icon_flx2'>
                                            <span className="material-icons"> schedule </span>
                                            <span className="edit"> 06:00:00 AM - 07:15:00 AM <span className="material-icons"> edit </span></span>
                                        </div> */}
                                        {/* <div className='dsp_flex'> */}
                                        <div className='time'>
                                            <label>Start</label>
                                            <input type="datetime-local" className='input' name='start' onChange={e => this.handleChange(e)} value={startDate} step="1" />
                                            {/* <p className='hours'>HH : MM : SS</p> */}
                                        </div>
                                        <div className='time' style={{ marginTop: '20px' }}>
                                            <label>End</label>
                                            <input type="datetime-local" className='input' name='end' value={endDate} onChange={e => this.handleEndDateChange(e)} step="1" />
                                            {/* <p className='input_value'>{assetInfo.start}</p> */}
                                        </div>
                                        {assetInfo && assetInfo.extendedProps && (assetInfo.extendedProps.scheduleId || assetInfo.extendedProps.scheduleUUID) && <div className="cl_buttons">
                                            <div className="form_buttons">
                                                {/* <button onClick={(e) => this.updateScheduleData(e)} className="add">Update</button> */}
                                                <button style={{
                                                    height: '30px',
                                                    marginTop: '22px',
                                                }} type="button" className="btn" onClick={e => self.updateScheduleData(e)} >{self.state.submitText ? "Updating..." : "Update"}</button>
                                            </div>
                                        </div>}
                                        {/* </div> */}
                                    </div>
                                    {/* <div className="field prv_next">
                                        <div className='item'>
                                            <div className='arrow'><span className="material-icons"> navigate_before </span>Previous Program</div>
                                            <div className='title'>envoi_channel_185668_5699_Live Stream  </div>
                                            <div className='time'> <span>Start</span> : 25-04-2023 04:10:14</div>
                                            <div className='time'> <span>End</span> :  25-04-2023 05:59:59</div>
                                        </div>
                                        <div className='item'>

                                            <div className='arrow next'>Next Program <span className="material-icons"> navigate_next </span></div>
                                            <div className='title'>envoi_channel_185668_5699_Live Stream  </div>
                                            <div className='time'> <span>Start</span> : 25-04-2023 07:16:00</div>
                                            <div className='time'> <span>End</span> : 25-04-2023 07:30:22</div>
                                        </div>
                                    </div> */}
                                    <div className="field">
                                        {/* <div className='column'>
                                            <div> <label className='dsp_flx'>Ad Duration </label></div>
                                            <div> <p >00:23:09</p></div>
                                        </div> */}

                                        <div className='column'>
                                            <div> <label className='dsp_flx'>Asset Duration </label></div>
                                            <div> <p >{runtime ? runtime : (assetInfo.extendedProps || {}).formattedDuration}</p></div>
                                        </div>

                                        <div className='column pdd'>
                                            <div> <label className='dsp_flx'>Program Duration </label></div>
                                            <div> <p >{runtime ? runtime : (assetInfo.extendedProps || {}).formattedDuration}</p></div>
                                        </div>
                                    </div>

                                    <div className='field preview_block'>
                                        <label>Preview</label>
                                        <div className='video' style={{ width: '100%', height: '50%' }}>
                                            {this.state.playbackUrlsData && <VideoPlayer
                                                videoStream={this.state.playbackURL || ''}
                                                eventData={this.state.playbackUrlsData}
                                                playerType="bitmovin"
                                                item={this.item || {}}
                                                title={this.title || ''}
                                                closePopup={this.closePopup}
                                                playerTimeChanged={this.playerTimeChanged}
                                            />}
                                        </div>
                                    </div>
                                </div>
                                <div className='side_tab'>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Schedule </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Metadata</a>
                                        </li>

                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                                            <div className='mrk_block'>
                                                <a href="#" className='add_segment' onClick={() => this.openSegmentPopup()}> <span className="material-icons"> add_circle_outline </span> Add Segment</a>


                                                {/* {this.state.markersData&& this.state.markersData.length>0 ?} */}
                                                {/* <div className='mrk_short'>Start Time <span> (Duration) </span></div> */}
                                                {/* <div className='mrk_short_hed'>
                                                    <div className='left'>
                                                        <div>6 Segment(s)</div>
                                                        <div className='dot'></div>
                                                        <div>8 Break(s)</div>
                                                    </div>
                                                    <div className='right'>
                                                        06:00:00 AM <span>(01:15:00)</span>
                                                    </div>
                                                </div> */}
                                                {this.state.isSementsLoading && <Loader />}
                                                {this.state.markersData && this.state.markersData.length > 0 ?
                                                    <> <div className="sd_items">
                                                        {this.state.markersData.map((segment, index) => (
                                                            <div className="sd_item" key={index}>
                                                                <div className='sd_item_wrp'>
                                                                    <div className="icon" style={(segment.markers && segment.markers.length > 0) ? { cursor: 'pointer' } : disabledStyle}>
                                                                        {segment.markers && segment.markers.length > 0 && <span className="material-icons" onClick={e => { this.collapseAndExpand(index); }}>{this.state['isExpand' + index] ? 'remove_circle_outline' : 'add_circle_outline'}</span>}
                                                                    </div>
                                                                    <div className="item_title">
                                                                        <div className='icon'>
                                                                            <span className="material-symbols-outlined"> image </span>
                                                                        </div>
                                                                        <div className='content'>
                                                                            <p className='title'>{segment.title} </p>
                                                                            <a href="#" className="material-symbols-outlined delete" onClick={() => this.deleteSegmentORMarker(segment._id, 'segment')}>delete</a>
                                                                            <div className='time_block'>
                                                                                <a href="#" className='add' onClick={() => this.openMarkerPopup(segment)}> <span className="material-icons">add_circle_outline</span>Add Break</a>
                                                                                <div className="right">
                                                                                    <div className='blk'>  Duration: <span>{segment.duration}</span></div>
                                                                                    {/* <div className='blk'>  Start: <span>06:10</span></div>
                                                                                <div className='blk'> End : <span>06:50</span></div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                {this.state['isExpand' + index] && <div className='main_sub'>
                                                                    {segment.markers && segment.markers.length > 0 && segment.markers.map((marker, markerIndex) => (
                                                                        <div className='main_item'>
                                                                            <div className='icon'>
                                                                                <span className="material-symbols-outlined"> image </span>
                                                                            </div>
                                                                            <div className='content'>
                                                                                <p className='title'>{marker.title}</p>
                                                                                <a href="#" className="material-symbols-outlined delete" onClick={() => this.deleteSegmentORMarker(marker._id, 'break')}>delete</a>
                                                                                <div className="right">
                                                                                    <div className='blk'>  Duration: <span>{marker.duration}</span></div>
                                                                                    <div className='blk'>  Start: <span>06:10</span></div>
                                                                                    <div className='blk'> End : <span>06:50</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>))}
                                                                    {/* <div className='main_item'>
                                                                        <div className='icon'>
                                                                            <span className="material-symbols-outlined"> image </span>
                                                                        </div>
                                                                        <div className='content'>
                                                                            <p className='title'>REVOLT Mixtape Ad Slate: 120 Seconds</p>
                                                                            <a href="#" className="material-symbols-outlined delete">delete</a>
                                                                            <div className="right">
                                                                                <div className='blk'>  Duration: <span>00:40</span></div>
                                                                                <div className='blk'>  Start: <span>06:10</span></div>
                                                                                <div className='blk'> End : <span>06:50</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='main_item'>
                                                                        <div className='icon'>
                                                                            <span className="material-symbols-outlined"> image </span>
                                                                        </div>
                                                                        <div className='content'>
                                                                            <p className='title'>REVOLT Mixtape Ad Slate: 120 Seconds</p>
                                                                            <a href="#" className="material-symbols-outlined delete">delete</a>
                                                                            <div className="right">
                                                                                <div className='blk'>  Duration: <span>00:40</span></div>
                                                                                <div className='blk'>  Start: <span>06:10</span></div>
                                                                                <div className='blk'> End : <span>06:50</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>}
                                                            </div>))}

                                                    </div>


                                                        {/* <div className="sd_items">
                                                    <div className="sd_item">
                                                        <div className="icon">
                                                            <span className="material-icons">add_circle_outline</span>
                                                        </div>
                                                        <div className="item_title">
                                                            <div className='icon'>
                                                                <span className="material-symbols-outlined"> image </span>
                                                            </div>
                                                            <div className='content'>
                                                                <p className='title'>The Barber of Birmingham ( Film) Abby Ginberg </p>
                                                                <a href="#" className="material-symbols-outlined delete">delete</a>
                                                                <div className='time_block'>
                                                                    <a href="#" className='add'> <span className="material-icons">add_circle_outline</span>Add Break</a>
                                                                    <div className="right">
                                                                        <div className='blk'>  Duration: <span>00:40</span></div>
                                                                        <div className='blk'>  Start: <span>06:10</span></div>
                                                                        <div className='blk'> End : <span>06:50</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sd_items">
                                                    <div className="sd_item">
                                                        <div className="icon">
                                                            <span className="material-icons">add_circle_outline</span>
                                                        </div>
                                                        <div className="item_title">
                                                            <div className='icon'>
                                                                <span className="material-symbols-outlined"> image </span>
                                                            </div>
                                                            <div className='content'>
                                                                <p className='title'>The Barber of Birmingham ( Film) Abby Ginberg </p>
                                                                <a href="#" className="material-symbols-outlined delete">delete</a>
                                                                <div className='time_block'>
                                                                    <a href="#" className='add'> <span className="material-icons">add_circle_outline</span>Add Break</a>
                                                                    <div className="right">
                                                                        <div className='blk'>  Duration: <span>00:40</span></div>
                                                                        <div className='blk'>  Start: <span>06:10</span></div>
                                                                        <div className='blk'> End : <span>06:50</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sd_items">
                                                    <div className="sd_item">
                                                        <div className="icon">
                                                            <span className="material-icons">add_circle_outline</span>
                                                        </div>
                                                        <div className="item_title">
                                                            <div className='icon'>
                                                                <span className="material-symbols-outlined"> image </span>
                                                            </div>
                                                            <div className='content'>
                                                                <p className='title'>The Barber of Birmingham ( Film) Abby Ginberg </p>
                                                                <a href="#" className="material-symbols-outlined delete">delete</a>
                                                                <div className='time_block'>
                                                                    <a href="#" className='add'> <span className="material-icons">add_circle_outline</span>Add Break</a>
                                                                    <div className="right">
                                                                        <div className='blk'>  Duration: <span>00:40</span></div>
                                                                        <div className='blk'>  Start: <span>06:10</span></div>
                                                                        <div className='blk'> End : <span>06:50</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                    </> :
                                                    <div className='no_segment'>
                                                        <span className="material-symbols-outlined"> event_busy </span>
                                                        <p>No Segments Found</p>
                                                    </div>
                                                }
                                            </div>


                                        </div>
                                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="home-tab">

                                            <div className='meta_right'>
                                                <div className="field">
                                                    <div className='column'>
                                                        <div> <label className='dsp_flx'>Type </label></div>
                                                        <div> <p >Longform</p></div>
                                                    </div>
                                                    <div className='column'>
                                                        <div> <label className='dsp_flx'>Genre</label></div>
                                                        <div> <p >talk</p></div>
                                                    </div>
                                                    <div className='column'>
                                                        <div> <label className='dsp_flx'>On-Air Date</label></div>
                                                        <div> <p >--</p></div>
                                                    </div>
                                                    <div className='column'>
                                                        <div> <label className='dsp_flx'>Rating</label></div>
                                                        <div> <p >TV-14</p></div>
                                                    </div>
                                                    <div className="inputgroup">
                                                        <p className="label">External ID(s)</p>
                                                        <select id="markerType" name="markerType">
                                                            <option value="">1 Available</option>
                                                            <option value="Ad Marker">Ad Marker</option>
                                                            <option value="Chapter Marker">Chapter Marker</option>
                                                            <option value="Subclips">Subclips</option>
                                                            <option value="Web Link">Web Link</option>
                                                            <option value="Media Tailor">Media Tailor</option>
                                                        </select>

                                                    </div>
                                                    <div className="inputgroup">
                                                        <p className="label">Content Right(s)</p>
                                                        <select id="markerType" name="markerType">
                                                            <option value="">1 Available</option>
                                                            <option value="Ad Marker">Ad Marker</option>
                                                            <option value="Chapter Marker">Chapter Marker</option>
                                                            <option value="Subclips">Subclips</option>
                                                            <option value="Web Link">Web Link</option>
                                                            <option value="Media Tailor">Media Tailor</option>
                                                        </select>

                                                    </div>
                                                </div>
                                                {/* <div className="field">
                                                    <label>Tags and Keywords</label>
                                                    <div className='tags'>
                                                        <span className='tag'>No Sleeping In The Room</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <span className='tag'>No Sleep</span>
                                                        <a href="#" className='add'>Add</a>

                                                    </div>
                                                </div> */}
                                                <div >
                                                    <div className="inputgroup">
                                                        <p className="label">Cast</p>
                                                        {cast && <SelectionsExample options={[]} className="custom-select form-control" selected={cast || []} placeholder='Cast' labelKey='cast' isView={true} />}
                                                    </div>
                                                    <div className="inputgroup">
                                                        <p className="label">Crew</p>
                                                        {crew && <SelectionsExample options={[]} className="custom-select form-control" selected={crew || []} placeholder='Crew' labelKey='crew' isView={true} />}
                                                    </div>
                                                    <div className="inputgroup">
                                                        <p className="label">Writer</p>
                                                        {writer && <SelectionsExample options={[]} className="custom-select form-control" selected={writer || []} placeholder='Writer' labelKey='item' isView={true} />}
                                                    </div>
                                                    <div className="inputgroup">
                                                        <p className="label">Distributer</p>
                                                        {distributer && <SelectionsExample options={[]} className="custom-select form-control" selected={distributer || []} placeholder='Distributer' labelKey='item' isView={true} />}
                                                    </div>

                                                    <div className="inputgroup">
                                                        <p className="label">Awards</p>
                                                        {award && <SelectionsExample options={[]} className="custom-select form-control" selected={award || []} placeholder='Awards' labelKey='item' isView={true} />}
                                                    </div>

                                                    <div className="inputgroup">
                                                        <p className="label">Studio</p>
                                                        {studio && <SelectionsExample options={[]} className="custom-select form-control" selected={studio || []} placeholder='Studio' labelKey='item' isView={true} />}
                                                    </div>

                                                    <div className='inputgroup'>
                                                        <p className="label"><span>Tags</span></p>
                                                        {tags && <SelectionsExample options={[]} className="custom-select form-control" selected={tags || []} placeholder='Tags' labelKey='item' isView={true} />}
                                                    </div>
                                                </div>
                                                {/* <div className='mrk_block'>
                                                    <div className="sd_items">
                                                        <div className="sd_item">
                                                            <div className="icon">
                                                                <span className="material-icons">add_circle_outline</span>
                                                            </div>
                                                            <div className="item_title">
                                                                <div className='left'>
                                                                    <div>Series</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="sd_items">
                                                        <div className="sd_item">
                                                            <div className="icon">
                                                                <span className="material-icons">add_circle_outline</span>
                                                            </div>
                                                            <div className="item_title">
                                                                <div className='left'>
                                                                    <div>Season</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sd_items">
                                                        <div className="sd_item">
                                                            <div className="icon">
                                                                <span className="material-icons">remove_circle_outline</span>
                                                            </div>
                                                            <div className="item_title">
                                                                <div className='left'>
                                                                    <div>Episode</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='main_sub'>
                                                        <div className="inputgroup">
                                                            <p className="label">Episode name</p>
                                                            <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" value="No Sleeping in the Trophy Room" />
                                                            <span className="errormsg"></span>
                                                        </div>
                                                        <div className="inputgroup">
                                                            <p className="label">Episode genre</p>
                                                            <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" value="No Sleeping in the Trophy Room" />
                                                            <span className="errormsg"></span>
                                                        </div>
                                                        <div className="inputgroup">
                                                            <p className="label">Episode short description</p>
                                                            <textarea className="channel_description" rows="4" placeholder="Description" name="description"></textarea>
                                                        </div>

                                                    </div>
                                                </div> */}



                                                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Metadata</a>
                                            </li> */}
                                                {/* <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Pattern</a>
                                        </li> */}
                                                {/* <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Linked Collection</a>
                                        </li> */}
                                                {/* </ul> */}
                                                {/* <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div className="inputgroup">
                                                    <p className="label">Cast</p>
                                                    {cast && <SelectionsExample options={[]} className="custom-select form-control" selected={cast || []} placeholder='Cast' labelKey='cast' isView={true} />}
                                                </div>
                                                <div className="inputgroup">
                                                    <p className="label">Crew</p>
                                                    {crew && <SelectionsExample options={[]} className="custom-select form-control" selected={crew || []} placeholder='Crew' labelKey='crew' isView={true} />}
                                                </div>
                                                <div className="inputgroup">
                                                    <p className="label">Writer</p>
                                                    {writer && <SelectionsExample options={[]} className="custom-select form-control" selected={writer || []} placeholder='Writer' labelKey='item' isView={true} />}
                                                </div>
                                                <div className="inputgroup">
                                                    <p className="label">Distributer</p>
                                                    {distributer && <SelectionsExample options={[]} className="custom-select form-control" selected={distributer || []} placeholder='Distributer' labelKey='item' isView={true} />}
                                                </div>

                                                <div className="inputgroup">
                                                    <p className="label">Awards</p>
                                                    {award && <SelectionsExample options={[]} className="custom-select form-control" selected={award || []} placeholder='Awards' labelKey='item' isView={true} />}
                                                </div>

                                                <div className="inputgroup">
                                                    <p className="label">Studio</p>
                                                    {studio && <SelectionsExample options={[]} className="custom-select form-control" selected={studio || []} placeholder='Studio' labelKey='item' isView={true} />}
                                                </div>

                                                <div className='inputgroup'>
                                                    <p className="label"><span>Tags</span></p>
                                                    {tags && <SelectionsExample options={[]} className="custom-select form-control" selected={tags || []} placeholder='Tags' labelKey='item' isView={true} />}
                                                </div> */}
                                                {/* <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div> */}
                                                {/* </div> */}
                                                {/* <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                        </div> */}
                                                {/* <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                        </div> */}
                                                {/* </div> */}

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {(assetInfo && assetInfo.extendedProps) && (assetInfo.extendedProps.scheduleId || assetInfo.extendedProps.scheduleUUID) && <div className='pop_footer'>
                                    {/* <p className='note'>All fields marked with * are required</p> */}
                                    <div className='btns'>
                                        {/* <button className='btn' onClick={e => self.setState({ isMetaDataReady: false })}>Cancel</button> */}
                                        <button className='btn submit' onClick={e => self.deleteSchedule(e)} >Delete</button>
                                    </div>
                                </div>}
                                {/* <div className='pop_footer'>
                                    <div className='btns'>
                                        <button className='btn' onClick={e => self.setState({ isMetaDataReady: false })}>Cancel</button>
                                        <button className='btn submit' onClick={e => self.scheduleData(e)} >Schedule</button>
                                    </div>
                                </div> */}
                            </div>
                        </div>}

                    </div>
                </div>
                <Footer />
            </React.Fragment >
        )

    }
}
const mapState = ({ loading, manageSchedulingData, manageSchedulesData, assets, playlistAssetsData, scheduleEventsData, playbackUrls, channels, manageChannelsData, mediatailorChannelData, markers, insertMarkerSuccess, updateMarkerSuccess, vodSourcesData, sourceLocationData }) => ({ loading, manageSchedulingData, manageSchedulesData, assets, playlistAssetsData, scheduleEventsData, playbackUrls, channels, manageChannelsData, mediatailorChannelData, markers, insertMarkerSuccess, updateMarkerSuccess, vodSourcesData, sourceLocationData });
const mapDispatch = { manageScheduling, manageSchedules, getAssets, playlistAssets, scheduleEventsToS3, getPlaybackUrls, manageChannels, getChannels, createMediatailorChannel, fetchMarkers, inserAssetMarkers, msTimeMilliseconds, getVodSources, getSourceLocation, deleteAssetMarkers };
export default withRouter(connect(mapState, mapDispatch)(Scheduling));