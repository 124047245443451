
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { exportCsv } from '../../redux/reducers/reducer';
import 'lodash'
import VideoMarkersList from './VideoMarkersList';
import VideoMarkersForm from './VideoMarkersForm';
class VideoMarkers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputsEnable: false,
            showForm: true,
            clearForm: false
        }
        this.formOpen = this.formOpen.bind(this);
        this.downloadCsv = this.downloadCsv.bind(this);
    }

    formOpen(e) {
        this.setState({ showForm: true, clearForm: e });
    }
    downloadCsv(args) {
        let csvData = this.props.markers;
        let title = "VideoMarkers.csv";
        // this.props.exportCsv(title, csvData);
    }
    render() {
        const { showForm, clearForm } = this.state;
        return (
            <div className="bhoechie-tab-content">
                {/* <p className="text-right u-close">
                    <a href="#" onClick={e => this.props.nav('none')} id="hide2"><img src={imagesAssetResourcesPrefix + themePrefix + "playerSidenavIcons/close.png"} /></a></p> */}
                 <h3 class="sub_title">Marker</h3>
                <div className="u-meta">
              
                        {/* {
                            showForm ? "Add Marker" : "Show Marker"} */}
                    <div className="u-blk-ht-max">
               
                        <div className="u-marker-tab"><ul>
                            <li className={showForm ? 'active' : ''}><a href="#" onClick={e => this.setState({ showForm: true, clearForm: false })}>Add</a></li>
                            <li className={clearForm ? 'active' : ''}><a href="#" onClick={e => this.setState({ showForm: false, clearForm: true })}>Show</a></li>
                        </ul>
                        </div>
                        {/* <p className="u-export_block">  {
                            showForm ? "" :
                                this.props.markers && this.props.markers.length > 0 ?
                                    <a href="#" onClick={e => this.downloadCsv(encodeURI)} className=" btn-orng1 u-export"><i className="fa fa-file-excel-o mr-1"></i> Export</a> : null
                        }</p> */}
                        {
                            showForm ? <VideoMarkersForm assetid={this.props.assetid} playertime={this.props.playertime} endtime={this.props.endtime} clearForm={clearForm} /> :
                                <VideoMarkersList assetid={this.props.assetid} formOpen={this.formOpen.bind(this)} />
                        }
                    </div>
                </div>
            </div >
        )
    }
}
const mapState = ({ markers }) => ({ markers });
const mapDispatch = {  };
export default connect(mapState, mapDispatch)(VideoMarkers);