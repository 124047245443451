
 import React, { Component } from 'react';
 import { connect } from 'react-redux';
 import { CompactPicker } from 'react-color';
 import { inserAssetMarkers, updateAssetMarkers, msTimeMilliseconds } from '../../redux/reducers/reducer';
 import 'lodash'
 import NumberFormat from 'react-number-format';
 import SuccessFailedPopup from './SuccessFailedPopup';
 import moment from 'moment';
//  import { hotJarUserIdentify } from '../utils/utils';
 class VideoMarkersForm extends Component {
     constructor(props) {
         super(props);
         if (props && props.singlemarker && props.singlemarker[0] && !props.clearForm) {
             const { title, comment, url, adType, adUrl, duration, frameTarget, markerColor,
                 markerType, _id, seektime, videoDuration, endtime, } = props.singlemarker[0];
 
 
             // const { VodSourceName, SourceLocationName, } = props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.Slate;
             // const { AvailNumber, SpliceEventId, AvailsExpected, UniqueProgramId } = props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage;
 
             // console.log('props.singlemarker[0]',props.singlemarker[0].mediaTailor.adBreak.Slate)
             this.state = {
                 errors: {},
                 title: title,
                 comment: comment,
                 url: url,
                 VodSourceName: props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.Slate && props.singlemarker[0].mediaTailor.adBreak.Slate.VodSourceName || "",
                 SourceLocationName: props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.Slate && props.singlemarker[0].mediaTailor.adBreak.Slate.SourceLocationName || "", AvailNumber: props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage.AvailNumber || "",
                 SpliceEventId: props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage.SpliceEventId || "",
                 AvailsExpected: props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage.AvailsExpected || "",
                 UniqueProgramId: props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage.UniqueProgramId || "",
                 adType: adType,
                 adUrl: adUrl,
                 duration: duration,
                 frameTarget: frameTarget,
                 markerColor: markerColor,
                 markerType: markerType,
                 markerid: _id,
                 seektime: seektime,
                 videoDuration: videoDuration,
                 endtime: endtime,
                 inputsEnable: markerType == 'Web Link' ? true : false,
                 updateForm: true,
                 insertSuccessPopup: false,
                 updateSuccessPopup: false,
                 insertFailedPopup: false,
                 updateFailedPopup: false,
                 formData: {},
                 durationTime: "00.00.00",
                 "mediaTailor": {
                     "adBreak": {
                         "Slate": {
                             "SourceLocationName": props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.Slate && props.singlemarker[0].mediaTailor.adBreak.Slate.SourceLocationName || "",
                             "VodSourceName": props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.Slate && props.singlemarker[0].mediaTailor.adBreak.Slate.VodSourceName || ""
                         },
                         "SpliceInsertMessage": {
                             "AvailNumber": props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage.AvailNumber || "",
                             "AvailsExpected": props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage.SpliceEventId || "",
                             "SpliceEventId": props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage.AvailsExpected || "",
                             "UniqueProgramId": props.singlemarker[0] && props.singlemarker[0].mediaTailor && props.singlemarker[0].mediaTailor.adBreak && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage && props.singlemarker[0].mediaTailor.adBreak.SpliceInsertMessage.UniqueProgramId || ""
                         }
                     }
                 }
             }
         } else {
             this.state = {
                 errors: {},
                 title: '',
                 comment: '',
                 url: '',
                 VodSourceName: "",
                 SourceLocationName: "", AvailNumber: "",
                 SpliceEventId: "",
                 AvailsExpected: "",
                 UniqueProgramId: "",
                 adType: '',
                 adUrl: '',
                 duration: '',
                 frameTarget: '',
                 markerColor: '',
                 markerType: '',
                 inputsEnable: false,
                 updateForm: false,
                 insertSuccessPopup: false,
                 insertSuccessPopup: false,
                 updateSuccessPopup: false,
                 insertFailedPopup: false,
                 updateFailedPopup: false,
                 formData: {},
                 saveClick: false,
                 updateClick: false,
                 endtime: 0,
                 durationErrorMessage: "",
                 "mediaTailor": {
                     "adBreak": {
                         "Slate": {
                             "SourceLocationName": "",
                             "VodSourceName": ""
                         },
                         "SpliceInsertMessage": {
                             "AvailNumber": "",
                             "AvailsExpected": "",
                             "SpliceEventId": "",
                             "UniqueProgramId": ""
                         }
                     }
                 }
             }
         }
         this.Submit = this.Submit.bind(this);
         this.onChange = this.onChange.bind(this);
         this.handleChange = this.handleChange.bind(this);
         this.handleChangeComplete = this.handleChangeComplete.bind(this);
         this.onChangeMarker = this.onChangeMarker.bind(this);
     }
     componentWillReceiveProps(nextProps) {
         if (nextProps.markers !== this.props.markers) {
             if (nextProps.markers && nextProps.markers._id) {
                 let data = nextProps.markers;
                 let obj = {};
                 obj.title = data.title;
                 obj.time = Math.round(data.seektime);
                 obj.duration = data.duration || 0;
                 obj.cssClasses = [data._id];
                 //markersArr.push(obj)
                 $("head").append('<style type="text/css">.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-' + data._id + ' {border-color:' + data.markerColor + '}</style>');
                 if (myUiManager) {
                     console.log('myUiManager', myUiManager)
                     myUiManager.addTimelineMarker(obj);
                 }
 
             }
         }
         if (nextProps.insertMarkerSuccess !== this.props.insertMarkerSuccess) {
 
             if (nextProps.insertMarkerSuccess) {
                 this.setState({
                     comment: '', title: '', url: '', frameTarget: '', duration: '', markerColor: '',
                     markerType: '', updateForm: false, insertSuccessPopup: true,
                     saveClick: false, adType: '', adUrl: '', endTime: '', endtime: '', startTime: '', seektime: '', VodSourceName: "",
                     SourceLocationName: "", AvailNumber: "",
                     SpliceEventId: "",
                     AvailsExpected: "",
                     UniqueProgramId: "",
                 });
             }
         }
         if (nextProps.updateMarkerSuccess !== this.props.updateMarkerSuccess) {
             if (nextProps.updateMarkerSuccess) {
                 this.setState({
                     comment: '', title: '', url: '', frameTarget: '', duration: '', markerColor: '',
                     markerType: '', updateForm: false, updateSuccessPopup: true, updateClick: false, adType: '', adUrl: '', endTime: '', endtime: '', startTime: '', seektime: '', VodSourceName: "",
                     SourceLocationName: "", AvailNumber: "",
                     SpliceEventId: "",
                     AvailsExpected: "",
                     UniqueProgramId: "",
 
                 });
             }
         }
         if (nextProps.insertMarkerFailed !== this.props.insertMarkerFailed) {
             if (nextProps.insertMarkerFailed) {
                 this.setState({
                     insertFailedPopup: true
                 });
             }
         }
         if (nextProps.updateMarkerFailed !== this.props.updateMarkerFailed) {
             if (nextProps.updateMarkerFailed) {
                 this.setState({
                     updateFailedPopup: true
                 });
             }
         }
     }
     handleChange(e) {
         player.pause();
         this.setState({ durationErrorMessage: "" })
         const { infoasset } = this.props;
         let durationValidate = infoasset && infoasset.duration;
         let inputDuration = (e.target.value);
         if (inputDuration != "") {
             let currentTime = this.props.playertime;
             let totalTime = parseFloat(inputDuration) + parseFloat(currentTime);
             let durationtime = moment.duration(totalTime, 'seconds');;
             let seconds = parseInt((durationtime / 1000) % 60)
                 , minutes = parseInt((durationtime / (1000 * 60)) % 60)
                 , hours = parseInt((durationtime / (1000 * 60 * 60)) % 24);
             hours = (hours < 10) ? "0" + hours : hours;
             minutes = (minutes < 10) ? "0" + minutes : minutes;
             seconds = (seconds < 10) ? "0" + seconds : seconds;
             let milliseconds = hours + ":" + minutes + ":" + seconds
             let formData = Object.assign({}, this.state.formData);
             formData["duration"] = inputDuration;
             formData["endtime"] = totalTime;
             this.setState({ endtime: totalTime, formData, duration: inputDuration })
             if (durationValidate < totalTime) {
                 this.setState({ durationErrorMessage: "duration must be less than player time" })
             }
         } else {
             this.setState({ endtime: 0, duration: "" })
         }
 
     }
     handleChangeComplete(color) {
         let self = this;
         let markerValue = color.hex;
         let formData = Object.assign({}, this.state.formData);
         formData["markerColor"] = markerValue;
         self.setState({ formData, markerColor: markerValue });
     };
 
     onChange(e, type) {
         if (!!this.state.errors[e.target.name]) {
             let errors = Object.assign({}, this.state.errors);
             delete errors[e.target.name];
             this.setState({
                 [e.target.name]: e.target.value,
                 errors
             });
         } else {
             let formData = Object.assign({}, this.state.formData);
             let mediaTailor = this.state.mediaTailor;
             const name = e.target.name;
             const value = e.target.value;
             mediaTailor["adBreak"][type][name] = value;
             formData["mediaTailor"] = mediaTailor;
             this.setState({ [name]: value, formData });
         }
 
     }
     onChangeMarker(e) {
         if (!!this.state.errors[e.target.name]) {
             let errors = Object.assign({}, this.state.errors);
             delete errors[e.target.name];
             this.setState({
                 [e.target.name]: e.target.value,
                 errors
             });
         } else {
             let formData = Object.assign({}, this.state.formData);
             const name = e.target.name;
             const value = e.target.value;
             formData[name] = value;
             console.log('formData', formData)
             formData.markerType == 'Web Link' && formData.markerType == 'Ad Marker' ? this.setState({ inputsEnable: true }) :
                 !this.state.updateForm ? this.setState({
                     inputsEnable: false,
                 }) : this.setState({ inputsEnable: false })
             this.setState({ [name]: value, formData });
         }
     }
 
     validate() {
         let isError = false;
         let errors = {};
         if (this.state.comment === '') {
             isError = true;
             errors.comment = "Enter Comment";
         }
         if (this.state.title === '') {
             isError = true;
             errors.title = "Enter Name";
         }
         if (this.state.duration === '') {
             isError = true;
             errors.duration = "Enter Duration";
         }
         if (this.state.markerType === '') {
             isError = true;
             errors.markerType = "Select Ad Marker";
         }
         if (this.state.markerColor === '') {
             isError = true;
             errors.markerColor = "Select Marker Color";
         }
         if (this.state.inputsEnable) {
             if (this.state.url === '') {
                 isError = true;
                 errors.url = "Enter URL";
             }
             if (this.state.frameTarget === '') {
                 isError = true;
                 errors.frameTarget = "Select Frame Target";
             }
         }
         this.setState({ errors });
         return isError;
     };
 
     Submit(e) {
         e.preventDefault();
         let self = this;
         if (self.state.updateForm) {
             this.setState({
                 updateClick: true,
                 saveClick: false
             });
         } else {
             this.setState({
                 updateClick: false,
                 saveClick: true
             });
         }
         if (self.props.playertime) {
             const err = self.validate();
             let data = this.state.formData;
             console.log('data', data)
             if (!err) {
                 if (!self.state.updateForm) {
                     data["seektime"] = self.props.playertime;
                     // data["endtime"] = self.props.endtime;
                     data["mode"] = "insert";
                     self.props.inserAssetMarkers(self.props.assetid, data);
                 } else {
                     data["mode"] = "update";
                     data["markerid"] = this.state.markerid;
                     self.props.updateAssetMarkers(self.props.assetid, data);
                 }
             }
             let userMarkersIdentify = {
                 "title": "Video Markers",
                 "page": "VideoMarkersForm",
                 "created": new Date(),
                 "markerType": data && data.markerType,
                 "comment": data && data.comment,
                 "duration": data && data.duration,
                 "title": data && data.title,
         
             }
            //  hotJarUserIdentify(userMarkersIdentify)
         }
     }
     ResetForm(e) {
         if (this.state.updateForm) {
             this.setState({
                 comment: '', title: '', url: '', frameTarget: '', duration: '', markerColor: '',
                 markerType: '', updateForm: false, updateClick: false,
                 saveClick: false, adType: '', adUrl: '', durationErrorMessage: "", endTime: '', endtime: '', VodSourceName: "",
                 SourceLocationName: "", AvailNumber: "",
                 SpliceEventId: "",
                 AvailsExpected: "",
                 UniqueProgramId: "",
             });
         } else {
             this.setState({
                 comment: '', title: '', url: '', frameTarget: '', duration: '', markerColor: '',
                 markerType: '', adType: '', adUrl: '', durationErrorMessage: "", endTime: '', endtime: '', VodSourceName: "",
                 SourceLocationName: "", AvailNumber: "",
                 SpliceEventId: "",
                 AvailsExpected: "",
                 UniqueProgramId: "",
             });
         }
     }
     render() {
         let self = this;
         const { comment, title, url, frameTarget, inputsEnable, duration, markerType, markerColor,
             updateForm, seektime, updateSuccessPopup, insertSuccessPopup, updateFailedPopup, insertFailedPopup, videoDuration, updateClick, saveClick, endtime, adType, adUrl, durationErrorMessage, VodSourceName, SourceLocationName, AvailNumber,
             SpliceEventId,
             AvailsExpected,
             UniqueProgramId, } = this.state;
         const { playertime } = this.props;
         return (
             <div>
                 <form style={{display:'block'}}>
                     {insertSuccessPopup ? <SuccessFailedPopup msg={"Successfully Inserted Marker"} heading={'Success'} dynamicClass={"custom_modal"} /> : null}
                     {insertFailedPopup ? <SuccessFailedPopup msg={"Failed to insert Marker"} heading={'Failed'} dynamicClass={"custom_modal"} /> : null}
                     {updateSuccessPopup ? <SuccessFailedPopup msg={"Successfully Updated Marker"} heading={'Success'} dynamicClass={"custom_modal"} /> : null}
                     {updateFailedPopup ? <SuccessFailedPopup msg={"Failed to Update"} heading={'Failed'} dynamicClass={"custom_modal"} /> : null}
                     <div className="add_marker">
                         <div className='row'>
                             <div className='col-md-12'>
                                 <div className="inputgroup">
                                     <p className="label">Title</p>
                                     <input type="text" placeholder="Name" name="title" value={title} onChange={e => this.onChangeMarker(e)}  />
                                   
                                     <span className="errormsg">{this.state.errors.title}</span>
                                 </div>
                             </div>
                             <div className='col-md-6'>
                                 <div className="inputgroup">
                                     <p className="label">Start Time</p>
                                     <input type="text" placeholder={playertime ? null : "Player Initializing..."} name="duration" value={updateForm ? this.props.msTimeMilliseconds(seektime) : playertime ? this.props.msTimeMilliseconds(playertime) : "00.00.00"} disabled={true} onChange={e => this.onChangeMarker(e)}  />
                                     
                                 </div>
                             </div>
                             <div className='col-md-6'>
                                 <div className="inputgroup">
                                     <p className="label">Duration</p>
                                     <input type="number" name="duration" value={duration} onChange={e => this.handleChange(e)}  />
                                  
                                     <span className="errormsg">{durationErrorMessage}</span>
                                     <span className="errormsg">{this.state.errors.duration}</span>
                                 </div>
                             </div>
                       
                         <div className='col-md-12'>
                             <div className="inputgroup">
                                 <p className="label">Comments</p>
                                 <textarea rows="2" cols="50" name="comment" value={comment} placeholder="Add Comment" onChange={e => this.onChangeMarker(e)} ></textarea>

                                 <span className="errormsg">{this.state.errors.comment}</span>
                             </div>
                         </div>
                         <div className='col-md-12'>
                             <h3 className='sub_title'>Options</h3>
                             <label className="font-white">Marker Color </label>
                             <div className="u-checkmark">
                                 <CompactPicker
                                     color={this.state.markerColor}
                                     onChangeComplete={this.handleChangeComplete}
                                 />
                                 {/* {
                                     markerColorArray && markerColorArray.map((item, i) => {
                                         var color = "checkmark1 color-" + item.slice(0, -6);
                                         return (
                                             <label key={i} className="container-u">
                                                 <input type="checkbox" name="markerColor" id={item} checked={markerColor === item ? 'checked' : ''} onChange={e => this.onChange(e)} value={item} />
                                                 <span className={color}></span>
                                             </label>
                                         )
                                     })
                                 } */}
                                 <span className="errormsg">{this.state.errors.markerColor}</span>

                             </div>
                         </div>
                         <div className='col-md-12'>
                                 <div className="inputgroup">
                                     <p className="label">Marker Type</p>
                                     <select id="markerType" name="markerType" value={markerType} onChange={e => this.onChangeMarker(e)}>
                                             <option value="">Select</option>
                                             {/* {settings && settings.markers && settings.markers.admarker && settings.markers.admarker.display === true && */}
                                                 <option value="Ad Marker">Ad Marker</option>
                                                 {/* }
                                             {settings && settings.markers && settings.markers.chaptermarker && settings.markers.chaptermarker.display === true && */}
                                               <option value="Chapter Marker">Chapter Marker</option>
                                                  {/*  }
                                             {settings && settings.markers && settings.markers.subclips && settings.markers.subclips.display === true && */}
                                                 <option value="Subclips">Subclips</option>
                                                 {/* }
                                             {settings && settings.markers && settings.markers.weblink && settings.markers.weblink.display === true && */}
                                                 <option value="Web Link">Web Link</option>
                                                 {/* }
                                             {settings && settings.markers && settings.markers.mediaTailor && settings.markers.mediaTailor.display === true && */}
                                                 <option value="Media Tailor">Media Tailor</option>
                                             {/* } */}
                                         </select>
                                         <span className="errormsg">{this.state.errors.markerType}</span>
                                     
                                     
                                 </div>
                             </div>
                             </div>
                          
                         
                             {/* <div className="">
                                 <label className="font-white">End Time</label>
                                 <input type="text" placeholder={endtime ? null : "Player Initializing..."} name="duration" value={this.props.msTimeMilliseconds(endtime)} disabled={true} className="form-control u-form-control input_dis u-run-time" />
                             </div> */}
                             {/* <div className="">
                                 <label className="font-white">Duration</label>
                                 <NumberFormat format="##:##:##:#" placeholder="00:00:00:0" name="duration" value={duration} onChange={e => this.onChange(e)} className="form-control u-form-control" />
                                 <p className="error_red">{this.state.errors.duration}</p>
                             </div>
                             <div className="">
                                 <label className="font-white">End Time</label>
                                 <input type="text" placeholder={endtime ? null : "Player Initializing..."} name="duration" value={updateForm ? this.props.msTimeMilliseconds(endtime) : endtime ? this.props.msTimeMilliseconds(endtime) : "00.00.00"} disabled={true} onChange={e => this.onChange(e)} className="form-control u-form-control input_dis u-run-time" />
                             </div> */}
                       

                      
                    
                            
                                  
                                        
 
                                         {/* <input type="radio" name="admarker" checked={admarker === "Ad Marker" ? true : false} onChange={e => this.onChange(e)} value="Ad Marker" /> Ad Marker */}
                                    
                                 {/* <p><input type="radio" name="admarker" checked={admarker === "Chapter Marker" ? true : false} onChange={e => this.onChange(e)} value="Chapter Marker" /> Chapter Marker</p>
                                 <p><input type="radio" name="admarker" checked={admarker === "Subclips" ? true : false} onChange={e => this.onChange(e)} value="Subclips" /> Subclips</p>
                                 <p><input type="radio" name="admarker" checked={admarker === "Web Link" ? true : false} onChange={e => this.onChange(e)} value="Web Link" /> Web Link</p> */}
                               
                            
                                 
                       
                         {inputsEnable || markerType == 'Ad Marker' ? 
                         
                         <div> 
                               <div className="inputgroup">
                                     <p className="label">AD Type</p>
                                     <select id="adType" name="adType" value={adType} onChange={e => self.onChangeMarker(e)}>
                                 <option value="">Select</option>
                                 <option value="vast">VAST</option>
                                 <option value="vpaid">VPAID</option>
                                 <option value="vmap">VMAP</option>
                                 <option value="ima">IMA</option>
                             </select>
                                 </div>

                                 <div className="inputgroup">
                                     <p className="label">AD Url </p>
                                     <input type="text" placeholder="" name="adUrl" value={adUrl} onChange={e => self.onChangeMarker(e)} />
                                 </div>
                                 {/* <span className="errormsg">{this.state.errors.url}</span> */}
                          
                         </div> : null}
                         {inputsEnable || markerType == 'Web Link' ? <div>
                             <div className="inputgroup">
                                 <p className="label">URL </p>
                                 <input type="text" placeholder="" name="url" value={url} onChange={e => this.onChangeMarker(e)} />
                                 <span className="errormsg">{this.state.errors.url}</span>
                             </div>

                             <div className="inputgroup">
                                 <p className="label">Frame Target  </p>
                                 <select name="frameTarget" defaultChecked={updateForm ? false : true} value={frameTarget} onChange={e => this.onChangeMarker(e)}>
                                 <option value="">Select</option>
                                 <option value="_blank">Blank</option>
                                 <option value="_self">Self</option>
                                 <option value="window">Window</option>
                             </select>
                           
                             </div>
                        
                            </div> : null}
                                  <span className="errormsg">{this.state.errors.frameTarget}</span>
                     
                         {inputsEnable || markerType == 'Media Tailor' ? <div> 
                         <p className="frm_hed">Slate </p>
                             <div className="inputgroup">
                                 <p className="label">Source Location Name </p>
                                 <input type="text" placeholder="SourceLocationName" name="SourceLocationName" value={SourceLocationName} onChange={e => this.onChange(e, 'Slate')} />
                     
                             </div>
                             <div className="inputgroup">
                                 <p className="label">Vod Source Name</p>
                                 <input type="text" placeholder="VodSourceName" name="VodSourceName" value={VodSourceName} onChange={e => this.onChange(e, 'Slate')} />
                             </div>
                             <p className="frm_hed">Splice Insert  </p>
                             <div className="inputgroup">
                                 <p className="label">Avail Number  </p>
                                 <input type="text" placeholder="Enter Number" name="AvailNumber" value={AvailNumber} onChange={e => this.onChange(e, "SpliceInsertMessage")} />
                             </div>
                             <div className="inputgroup">
                                 <p className="label">Avails Expected</p>
                                 <input type="text" placeholder="Enter" name="AvailsExpected" value={AvailsExpected} onChange={e => this.onChange(e, "SpliceInsertMessage")} />
                             </div>
                             <div className="inputgroup">
                                 <p className="label">Splice Event Id</p>
                                 <input type="text" placeholder="Enter Id" name="SpliceEventId" value={SpliceEventId} onChange={e => this.onChange(e, "SpliceInsertMessage")} />
                             </div>
                             <div className="inputgroup">
                                 <p className="label">Unique Program Id</p>
                                 <input type="text" placeholder="Enter Id" name="UniqueProgramId" value={UniqueProgramId} onChange={e => this.onChange(e, "SpliceInsertMessage")} />
                             </div>

                         </div> : null}
                         <div className="u-ok-btn transcription markrbtn">
                             {!updateForm ? durationErrorMessage == "" && <div onClick={e => this.Submit(e)} className={"btn btn-orng1"}>{!updateForm && saveClick ? 'Saving..' : 'Save'}</div> :
                                 <div onClick={e => this.Submit(e)} className={ "btn btn-orng1"}>{updateForm && updateClick ? 'Updating..' : 'Update'}</div>
                             }
                             <div onClick={this.ResetForm.bind(this)} className={"btn btn-orng1 reset"}>Reset</div>
                         </div>
                     </div>
                 </form>
             </div>
         )
     }
 }
 // const markerColorArray = ['greenmarker', 'pinkmarker', 'lightpinkmarker', 'orangemarker', 'yellowmarker', 'whitemarker', 'bluemarker', 'lightbluemarker'];
 const mapState = ({ comments, loading, markers, singlemarker, insertMarkerSuccess, updateMarkerSuccess, insertMarkerFailed, updateMarkerFailed }) => ({
     comments, loading, markers, singlemarker, insertMarkerSuccess, updateMarkerSuccess, insertMarkerFailed, updateMarkerFailed
 });
 const mapDispatch = { inserAssetMarkers, updateAssetMarkers, msTimeMilliseconds };
 export default connect(mapState, mapDispatch)(VideoMarkersForm);
 