export const ACTIVATION_CODE = "ACTIVATION_CODE";
export const ADD_PROFILE = "ADD_PROFILE";
export const APPROVED_ASSET = "APPROVED_ASSET";
export const ASSET_PAYMENT = "ASSET_PAYMENT";
export const BRAINTREE_CUSTOMER = "BRAINTREE_CUSTOMER";
export const GENERATE_ACTIVATION_CODE = "GENERATE_ACTIVATION_CODE";
export const GENERATE_USER_RELATION = "GENERATE_USER_RELATION";
export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ALL_PARTNERS = "GET_ALL_PARTNERS";
export const GET_ASSET_INFO = "GET_ASSET_INFO";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_EVENT_INFO = "GET_EVENT_INFO";
export const GET_CHANNELS = "GET_CHANNELS";
export const GET_REGIONS = "GET_REGIONS";
export const GET_USER = "GET_USER";
export const GET_WEBSITE_DEFAULT_DATA = "GET_WEBSITE_DEFAULT_DATA";
export const ITEMS_IS_LOADING = "ITEMS_IS_LOADING";
export const GET_CLIPS = "GET_CLIPS";
export const CREATE_CLIPS = "CREATE_CLIPS";
export const GIVE_VOTE = "GIVE_VOTE";
export const GET_LOCATION = 'GET_LOCATION';
export const GET_EVENTS = "GET_EVENTS";
export const GET_ASSETS= 'GET_ASSETS';
export const GET_PLAYBACK_URLS= 'GET_PLAYBACK_URLS';
export const MANAGE_CHANNELS= 'MANAGE_CHANNELS';

export const CREATE_IVS_CHANNEL= 'CREATE_IVS_CHANNEL';
export const MANAGE_PLAYLISTS= 'MANAGE_PLAYLISTS';
export const EDIT_ASSET= 'EDIT_ASSET';
export const MANAGE_LOOKUPS = 'MANAGE_LOOKUPS'; 
export const EMBED_LINKS_DATA= 'EMBED_LINKS_DATA';
export const MANAGE_EMBED_DATA= 'MANAGE_EMBED_DATA'; 


export const MANAGE_SCHEDULING= 'MANAGE_SCHEDULING'; 
export const MANAGE_SCHEDULES= 'MANAGE_SCHEDULES'; 
export const PLAYLIST_ASSETS= 'PLAYLIST_ASSETS'; 
export const SCHEDULE_EVENTS= 'SCHEDULE_EVENTS'; 


export const ADD_ASSET_MARKERS = "ADD_ASSET_MARKERS";
export const FETCH_ASSET_MARKERS = "FETCH_ASSET_MARKERS";
export const FORM_SUCCESS = "FORM_SUCCESS";
export const GET_SINGLE_MARKER = "GET_SINGLE_MARKER";
export const UPDATE_MARKER_SUCCESS = "UPDATE_MARKER_SUCCESS";
export const UPDATE_MARKER_FAILED = "UPDATE_MARKER_FAILED";
export const UPDATE_MARKER_LOADING = "UPDATE_MARKER_LOADING";
export const INSERT_MARKER_SUCCESS = "INSERT_MARKER_SUCCESS";
export const INSERT_MARKER_FAILED = "INSERT_MARKER_FAILED";
export const INSERT_MARKER_LOADING = "INSERT_MARKER_LOADING";

export const CREATE_MEDIATAILOR_CHANNAL = "CREATE_MEDIATAILOR_CHANNAL";

export const GET_VOD_SOURCES = "GET_VOD_SOURCES";
export const GET_SOURCE_LOCATION = "GET_SOURCE_LOCATION";
