
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { msTimeMilliseconds, singleMarkerFromList, fetchMarkers } from '../../redux/reducers/reducer';
import _ from 'lodash';
import DeleteMarkerPopup from './DeleteMarkerPopup';

class VideoMarkersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: props.markers,
            markersFinal: props.markers
        }
        this.Submit = this.Submit.bind(this);
        this.tagFilter = this.tagFilter.bind(this);
        this.timeBasedClick = this.timeBasedClick.bind(this);
    }

    timeBasedClick(e) {
        player.seek(e);
        player.pause();
    }

    Submit(e) {
        this.props.singleMarkerFromList(e);
        this.props.formOpen(false);
    }

    tagFilter(e) {
        const value = e.target.value;
        var updatedList = this.state.markersFinal;
        updatedList = updatedList.filter(function (item) {
            return item['title'].toLowerCase().search(
                value.toLowerCase()) !== -1;
        });
        this.setState({ markers: updatedList });

    }
    componentDidMount() {
        let self = this;
        let formData = {};
        formData["mode"] = "fetch";
        self.props.fetchMarkers(self.props.assetid, formData);
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.markers !== this.props.markers) {
            this.setState({
                markers: nextProps.markers,
                markersFinal: nextProps.markers
            });
        }
    }

    render() {
        if (this.props.markers && this.props.markers.length == 0) {
            return (<p className="text-center mt-20">No Markers Added</p>)
        }
        return (
            <div className="panel-group markers_list" id="accordion" role="tablist" aria-multiselectable="true">
                <div className=" u-search">
                    <div id="imaginary_container">
                        <div className="input-group stylish-input-group">
                            <input className="form-control" onChange={e => this.tagFilter(e)} placeholder="Search by Title" />
                            <span className="input-group-addon">

                                <span className="fa fa-search"></span>

                            </span>
                        </div>
                    </div>
                </div>
                {this.state.markers && this.state.markers.length > 0 ?
                    this.state.markers && this.state.markers.map((task, i) => {
                        let str, res, res_url, title, seektime, endtime, comments, admarker, markerColor, markerid, frameTarget, name, adType, adUrl,res_adUrl;
                        title = task && task.title;
                        seektime = task && task.seektime;
                        endtime = task && task.endtime;
                        comments = task && task.comment;
                        admarker = task && task.admarker;
                        markerColor = task && task.markerColor;
                        markerid = task && task._id;
                        frameTarget = task && task.frameTarget;
                        name = task && task.name;
                        adType = task && task.adType;
                        adUrl = task && task.adUrl;

                        if (task.url && task.url.length > 30) {
                            str = task.url;
                            res = str.substring(0, 30);
                            res_url = res + "...";
                        } else {
                            res_url = task.url;
                        }
                        if (task.adUrl && task.adUrl.length > 30) {
                            str = task.adUrl;
                            res = str.substring(0, 30);
                            res_adUrl = res + "...";
                        } else {
                            res_adUrl = task.adUrl;
                        }
                        let adMarkerurls;
                        let webLinkurls;
                        if (task.admarker == "Web Link") {
                            webLinkurls = true;
                        } else {
                            webLinkurls = false;
                        }
                        if (task.admarker == "Ad Marker") {
                            adMarkerurls = true;
                        } else {
                            adMarkerurls = false;
                        }
                        return (
                            <div key={i} className="panel panel-default">
                                <div className="panel-heading relpos" role="tab" id={i + '2'}>
                                    <h4 className="panel-title">
                                        <a className="ht-30" role="button" data-toggle="collapse" data-parent="#accordion"
                                            href={"#" + (i + 200)} aria-expanded="false" aria-controls={i}>
                                            <span className="accordion-title u-eclips">{task && task.title}</span>
                                        </a>
                                    </h4>
                                    <div className="marker_actions u-new-change">
                                        <a href="#" data-toggle="tooltip" data-placement="top" title="Update" onClick={e => this.Submit(markerid)} className="btn btn-warning-link greylink"><i className="fa fa-edit"></i></a>
                                        <a href="#" data-toggle="tooltip" data-placement="top" title="Play" onClick={e => this.timeBasedClick(seektime)} className="btn btn-warning-link greylink"><i className="fa fa-play"></i></a>
                                        <DeleteMarkerPopup assetid={this.props.assetid} markerid={markerid} />
                                    </div>
                                </div>
                                <div id={i + 200} className={i == 0 ? "panel-collapse collapse in" : "panel-collapse collapse"} role="tabpanel"
                                    aria-labelledby={i + '2'}>
                                    <div className="panel-body">
                                        <p className="marker_txt" data-toggle="tooltip" data-placement="top" title="Title"><i className="fa fa-vcard-o margrt10"></i>{title}</p>
                                        <div className="time_block">
                                            <p className="marker_txt" style={{ 'cursor': 'pointer' }} data-toggle="tooltip" data-placement="top" title="Seek Time" onClick={e => this.timeBasedClick(seektime)} >
                                                <i className="fa fa-clock-o margrt10"></i>
                                                {this.props.msTimeMilliseconds(seektime)}
                                            </p>
                                            <p className="marker_txt" style={{ 'cursor': 'pointer' }} data-toggle="tooltip" data-placement="top" title="End Time">
                                                <i className="fa fa-clock-o margrt10"></i>
                                                {this.props.msTimeMilliseconds(endtime)}
                                            </p>
                                        </div>
                                        <p className="marker_txt" data-toggle="tooltip" data-placement="top" title="Marker Color"><i className="fa fa-paint-brush margrt10"></i>


                                            <div style={{ 'background-color': markerColor, 'width': '12px', 'height': '12px', 'display': 'inline-block' }}></div>
                                        </p>
                                        <p className="marker_txt" data-toggle="tooltip" data-placement="top" title="Marker">
                                            <i className="fa fa fa-bookmark margrt10"></i>
                                            {admarker}
                                        </p>
                                        {adMarkerurls ?
                                            <div>
                                                <p className="marker_txt" data-toggle="tooltip" data-placement="top" title="AD Url">
                                                    <i className="fa fa-globe margrt10"></i>
                                                    <input type="text" className="nobg_input" value={res_adUrl} />
                                                </p>
                                                <p className="marker_txt" data-toggle="tooltip" data-placement="top" title="AD Type">
                                                    <i className="fa fa-window-restore margrt10"></i>
                                                    {adType}
                                                </p>
                                            </div> : null}
                                        {webLinkurls ?
                                            <div>
                                                <p className="marker_txt" data-toggle="tooltip" data-placement="top" title="Url">
                                                    <i className="fa fa-globe margrt10"></i>
                                                    <input type="text" className="nobg_input" value={res_url} />
                                                </p>
                                                <p className="marker_txt" data-toggle="tooltip" data-placement="top" title="Frame Target">
                                                    <i className="fa fa-window-restore margrt10"></i>
                                                    {frameTarget}
                                                </p>
                                            </div> : null}
                                        <p className="marker_txt nobot_brd" data-toggle="tooltip" data-placement="top" title="Comment">
                                            <i className="fa fa-comments margrt10"></i>
                                            {comments}
                                        </p>
                                        <p className="marker_txt" data-toggle="tooltip" data-placement="top" title="User">
                                            <i className="fa fa-user margrt10"></i>
                                            {name}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        )
                    }
                    ) : <div>No results found..</div>}
            </div>
        )
    }
}

const mapState = ({ markers, singlemarker }) => ({ markers, singlemarker });
const mapDispatch = { msTimeMilliseconds, singleMarkerFromList, fetchMarkers };
export default connect(mapState, mapDispatch)(VideoMarkersList);