
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  }
};
Modal.setAppElement('#root')
class SuccessFailedPopup extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      uuid: ''
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }
  afterOpenModal() {
    this.subtitle.style.color = '#f00';
  }
  closeModal() {
    this.setState({ modalIsOpen: false });
    this.props.popupclose(false);
  }
  render() {
    const { msg, heading, dynamicClass } = this.props;
    return (
      <div>
        <div >
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            className={dynamicClass}
          >
            <a href="#" className="u-alert-close" onClick={this.closeModal}><img src={imagesAssetResourcesPrefix +  themePrefix + "playerSidenavIcons/close.png"} /></a>
            <h4 ref={subtitle => this.subtitle = subtitle}>{heading}</h4>
            <p ref={subtitle => this.subtitle = subtitle} className="mb-1">{msg}</p>
          </Modal>
        </div>
      </div>
    );
  }
}
const mapState = ({ }) => ({});
const mapDispatch = {};
export default connect(mapState, mapDispatch)(SuccessFailedPopup);
